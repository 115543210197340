<template>
  <div>
    <v-app-bar :class="{'top-bar-green':whiteBar}" class="top-bar" flat>
      <v-app-bar-title v-show="mobile && !dashboardAdmin">
        <template v-slot:text>
          <v-img v-if="whiteBar" src="@/assets/img/logo-green.svg" @click="homeNavigation">
            <template v-slot:placeholder>
              <image-loader></image-loader>
            </template>
          </v-img>
          <v-img v-else src="@/assets/img/logo.svg" @click="homeNavigation">
            <template v-slot:placeholder>
              <image-loader></image-loader>
            </template>
          </v-img>
        </template>
      </v-app-bar-title>
      <v-app-bar-nav-icon v-show="mobile && !dashboardAdmin" class="nav-icon-mobile"
                          @click="showNavigationDrawer = !showNavigationDrawer"></v-app-bar-nav-icon>

      <v-container v-if="!mobile || dashboardAdmin"
                   class="mx-auto d-flex align-center justify-center container-top-bar">
        <v-list v-model:opened="listExpanded" v-model:selected="selected" active-class="active-menu" class="menu" nav>
          <v-list-item class="item-logo" link to="/" value="home">
            <v-img v-if="whiteBar" class="logo" src="@/assets/img/logo-green.svg">
              <template v-slot:placeholder>
                <image-loader></image-loader>
              </template>
            </v-img>
            <v-img v-else class="logo" src="@/assets/img/logo.svg">
              <template v-slot:placeholder>
                <image-loader></image-loader>
              </template>
            </v-img>
          </v-list-item>
          <template v-for="link in links" v-if="!dashboardAdmin" :key="link">
            <v-list-item v-if="!link.children || !link.children.length" :title="link.title" :to="link.href"
                         :value="link.value" class="text-bar" link></v-list-item>
            <template v-else>
              <v-list-group :value="link.value" class="item-category">
                <template v-slot:activator="{ props }">
                  <v-list-item :title="link.title" v-bind="props"></v-list-item>
                </template>
                <v-list-item v-for="l in link.children" :key="l" :title="l.title" :to="l.href" :value="l.value"
                             class="subitem" link @click="listExpanded = []"></v-list-item>
              </v-list-group>
            </template>
          </template>
        </v-list>

        <div class="space-top"></div>

        <v-container v-if="isLoggedIn" class="avatar-section">

          <user-avatar v-if="!dashboardAdmin"
                       @click="$router.push(isAdmin ? '/dashboard/admin/home' : '/dashboard/user/home')"></user-avatar>
          <router-link v-else to="/dashboard/admin/home">{{ currentUser[USER_FIELDS.email] }}</router-link>
          <v-btn @click="logout">{{ $t(pageLabelPrefix + 'logout') }}</v-btn>
        </v-container>

        <v-container v-else class="btn-access-section">
          <v-list nav>
            <v-btn :class="{'b-acc':whiteBar}" :title="$t(pageLabelPrefix+'login')" :value="$t(pageLabelPrefix+'login')"
                   to="/account/login">
              {{ $t(pageLabelPrefix + 'login') }}
            </v-btn>
            <v-btn :class="(whiteBar)?'b-acc':'b-secondary'" :title="$t(pageLabelPrefix+'signup')"
                   :value="$t(pageLabelPrefix+'signup')" to="/account/signup">
              {{ $t(pageLabelPrefix + 'signup') }}
            </v-btn>
          </v-list>
        </v-container>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer v-model="showNavigationDrawer" location="left" temporary class="nav-mobile">
      <div class="container-top-bar">

        <div class="wrap-logo-menu">
          <v-app-bar-title v-show="mobile">
            <template v-slot:text>
              <v-img v-if="whiteBar" src="@/assets/img/logo-green.svg" @click="homeNavigation">
                <template v-slot:placeholder>
                  <image-loader></image-loader>
                </template>
              </v-img>
              <v-img v-else src="@/assets/img/logo.svg" @click="homeNavigation">
                <template v-slot:placeholder>
                  <image-loader></image-loader>
                </template>
              </v-img>
            </template>
          </v-app-bar-title>
          <v-app-bar-nav-icon v-show="mobile" class="nav-icon-mobile"
                              @click="showNavigationDrawer = !showNavigationDrawer"></v-app-bar-nav-icon>
        </div>

        <v-container v-if="isLoggedIn" class="avatar-section">
          <router-link to="/dashboard/user/home" @click="showNavigationDrawer = false">
            <user-avatar v-if="!dashboardAdmin"></user-avatar>
            <span v-else>{{ currentUser[USER_FIELDS.email] }}</span>
          </router-link>
          <v-btn @click="logout">{{ $t(pageLabelPrefix + 'logout') }}</v-btn>
        </v-container>

        <v-list v-model:opened="opened" v-model:selected="selected" active-class="active-menu" class="menu" nav>
          <template v-for="link in links" v-if="!dashboardAdmin" :key="link">
            <v-list-item v-if="!link.children || !link.children.length" :title="link.title" :to="link.href"
                         :value="link.value" class="text-bar" link @click="showNavigationDrawer = false"></v-list-item>
            <template v-else>
              <v-list-group :value="link.value" class="item-category">
                <template v-slot:activator="{ props }">
                  <v-list-item :title="link.title" v-bind="props"></v-list-item>
                </template>
                <v-list-item v-for="l in link.children" :key="l" :title="l.title" :to="l.href" :value="l.value"
                             class="subitem" link @click="showNavigationDrawer = false"></v-list-item>
              </v-list-group>
            </template>
          </template>
        </v-list>

        <v-container v-if="!isLoggedIn" class="btn-access-section">
          <v-list nav>
            <v-btn :class="{'b-acc':whiteBar}" :title="$t(pageLabelPrefix+'login')" :value="$t(pageLabelPrefix+'login')"
                   to="/account/login" @click="showNavigationDrawer = false">
              {{ $t(pageLabelPrefix + 'login') }}
            </v-btn>
            <v-btn :class="(whiteBar)?'b-acc':'b-secondary'" :title="$t(pageLabelPrefix+'signup')"
                   :value="$t(pageLabelPrefix+'signup')" to="/account/signup" @click="showNavigationDrawer = false">
              {{ $t(pageLabelPrefix + 'signup') }}
            </v-btn>
          </v-list>
        </v-container>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import {useUserStore} from "@/stores/user";
import {storeToRefs} from "pinia";
import {ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useDisplay} from "vuetify";
import UserAvatar from "@/components/UserAvatar.vue";
import {USER_FIELDS} from "@/utilities/enums";
import {useAppStore} from "@/stores/app";
import ImageLoader from "@/components/ImageLoader.vue";

const emits = defineEmits(['openDrawer']);

const $router = useRouter();
const $route = useRoute();
const userStore = useUserStore();
const appStore = useAppStore();
const {isLoggedIn, currentUser, isAdmin} = storeToRefs(userStore);
const {t: $t} = useI18n();
const {mobile} = useDisplay();

const listExpanded = ref([]);

const pageLabelPrefix = 'navigation.';

const selected = ref([]);
const opened = ref([]);
const showNavigationDrawer = ref(false);

watch($route, to => {
  listExpanded.value = [];
//1) rimuovo lo stato active sul menu quando esco dalle voci del menu (p.e. perché clicco su una voce del footer menu o vado nella dashboard - non capisco perché non lo faccia da solo)
  let found = false;
  for(let i = links.length - 1; i >= 0; --i){
    const l = links[i];
    if(l.href === to.path){
      found = true;
      break;
    }
    if(l.children){
      for(let j = l.children.length - 1; j >= 0; --j){
        const lj = links[j];
        if(lj.href === to.path){
          found = true;
          break;
        }
      }
    }
  }
  if(!found){
    selected.value = [];
  }

  //2) vedo se la pagina corrente necessita dello stile bianco della barra
  calcWhiteBar($route);

  //3) verifico se sono in dashboardAdmin
  dashboardAdmin.value = to.path.startsWith('/dashboard/admin');
});

watch(showNavigationDrawer, newValue => {
  if(newValue){
    whiteBar.value = false;
    //all'apertura del drawer, torno in cima alla pagina
    window.scrollTo(0, 0);

    //riapro le voci di menu da aprire
    opened.value = [];
    links.forEach(l => {
      if(l.children && l.children.find(c => c.href === $route.path)){
        opened.value.push(l.value);
      }
    });
  }
  else{
    //vedo se la pagina corrente necessita dello stile bianco della barra
    calcWhiteBar($route);
  }

  emits('openDrawer', newValue);
});

const whiteBarPagesNames = ['fund'];
const whiteBarPagesPathPrefixes = ['/dashboard', '/account', '/project'];
const whiteBarPagesMobilePathPrefixes = ['/account', '/project'];
const whiteBar = ref(false);
const dashboardAdmin = ref(false);

const links = [{
  title: $t(pageLabelPrefix + 'fund'),
  value: $t(pageLabelPrefix + 'fund'),
  children: [
    {
      title: $t(pageLabelPrefix + 'equity'),
      value: $t(pageLabelPrefix + 'equity'),
      href: '/equity',
    },
    {
      title: $t(pageLabelPrefix + 'debt'),
      value: $t(pageLabelPrefix + 'debt'),
      href: '/debt',
    }]
},
  {
    title: $t(pageLabelPrefix + 'collect'),
    value: $t(pageLabelPrefix + 'collect'),
    href: '/collect'
  },
  {
    title: $t(pageLabelPrefix + 'how_it_works'),
    value: $t(pageLabelPrefix + 'how_it_works'),
    href: '/how-it-works'
  },
  {
    title: $t(pageLabelPrefix + 'us'),
    value: $t(pageLabelPrefix + 'us'),
    href: '/about-us'
  },
  {
    title: $t(pageLabelPrefix + 'faq'),
    value: $t(pageLabelPrefix + 'faq'),
    href: '/faq'
  }];

watch(mobile, () => calcWhiteBar($route));

function logout(){
  appStore.showSpinner();
  userStore.logout().then(() => {
    appStore.hideSpinner();
  });
}

function homeNavigation(){
  $router.push('/');
}

function calcWhiteBar(route){
  //vedo se la pagina corrente necessita dello stile bianco della barra
  whiteBar.value = whiteBarPagesNames.some(p => p === route.name) ||
      (!mobile.value && whiteBarPagesPathPrefixes.some(p => route.path.startsWith(p))) ||
      (mobile.value && whiteBarPagesMobilePathPrefixes.some(p => route.path.startsWith(p)));

  //caso specifico logo admin in mobile
  if(dashboardAdmin.value && mobile.value){
    whiteBar.value = true;
  }
}
</script>

<style lang="less" scoped>
@width-logo: 187px;

.top-bar {
  position: relative;
  display: flex;
  // justify-content: flex-end;
  align-content: stretch;
  background: transparent !important;
  overflow: visible;
  padding-top: 15px !important;

  &.top-bar-green {
    .subitem {
      background: @secondary-color;
      color: @white;
    }

    .menu {
      &:before {
        background: @secondary-color;
      }
    }

    //colore elementi attivi
    .active-menu {
      &:before {
        color: @secondary-color;
      }
    }
  }

  //un elemento del menu che può avere dei sotto elementi
  .item-category {
    height: 73px;

    & > div {
      height: 100%;
    }
  }

  //sotto elementi
  .subitem {
    background: @white;
    color: @secondary-color;
    text-align: center;
    padding-inline: 5px 10px !important;
    padding: 5px 10px !important;
    margin-bottom: 10px !important;
    min-height: 30px;
    position: initial !important;

    &.active, &:hover, &.active-menu {
      background: @primary-color;
    }
  }

  // elemento attivo del menu
  .active-menu {
    position: relative;

    &:before {
      content: "\F0360";
      font: normal normal normal 24px/1 "Material Design Icons";
      font-size: 40px;
      color: @white;
      position: absolute;
      top: 58px;
      left: 0;
      right: 0;
      margin: auto;
      width: 42px;
    }
  }
}


.v-list {
  display: flex;
}

.menu {
  column-gap: 40px;
  background: transparent;
  position: relative;
  overflow: visible;

  //riga sotto il menu
  &:before {
    content: '';
    display: block;
    width: 100%;
    left: 0;
    height: 2px;
    background: @white;
    position: absolute;
    bottom: 0;
  }

  .v-list-item--nav {
    padding-inline-start: 12px;
    padding-inline-end: 12px;
    font-size: 20px;
  }

  .item-logo {
    //allineo il logo a sinistra
    position: relative;
    left: -32px;

    //rimuovo il triangolo di voce attiva da sotto il logo
    &:before {
      display: none;
    }
  }
}

//spazio tra il menu e i bottoni a destra
.space-top {
  flex-grow: 1;
}

.btn-access-section {
  width: auto;
  max-width: 100%;

  .v-list {
    background: transparent;
  }

  .v-btn:first-child {
    margin-right: 20px;
  }
}

.avatar-section {
  width: auto;
  display: inline-flex;
  align-items: center;

  .v-list {
    background: transparent;
  }

  .user-avatar {
    margin: 0 20px;
  }
}

.logo {
  width: @width-logo;
  height: 65px;
}

//stile scritte della barra
.text-bar {
  color: @white;
}

//la barra con accedi/registrati bianca c'è SOLO quando siamo su una di quelle due pagine, dunque le classi
//che utilizzo per la barra bianca su quei due bottoni sono speciali
.b-acc {
  color: @secondary-color;
  background: @light-grey;

  &.v-btn--active {
    background: @secondary-color;
    color: @primary-color;
  }
}

@media screen and (max-width: 1919px) {
  .menu {
    column-gap: 30px;
  }
}

//STILE MOBILE
.mobile-app {
  &.app-dash {
    .v-toolbar {
      background: @secondary-color !important;
    }
  }
}

.v-app-bar-title {
  .v-img {
    max-width: 200px;
    cursor: pointer;
  }
}

//contenitore menu a lato
.nav-mobile {
  width: 100% !important;
  top: 0 !important;
  background: @secondary-color;
  height: 100% !important;

  .menu {
    flex-direction: column;
    margin-top: 90px;
    border-left: 2px solid @white;

    //nascondo la riga orizzontale sotto il menu
    &:before {
      display: none;
    }
  }

  // elemento attivo del menu
  .active-menu {
    position: relative;

    &:before {
      content: "\F035F";
      font: normal normal normal 24px/1 "Material Design Icons";
      font-size: 40px;
      color: @white;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -24px;
      margin: auto;
      width: 42px;
      height: 42px;
    }
  }

  .btn-access-section {
    padding-left: 0;

    .v-list {
      padding-left: 0;
      flex-direction: column;
      row-gap: 30px;
      align-items: flex-start;
    }
  }

  //stile icona loggato ed esci
  .avatar-section {
    border-left: 2px solid @white;
    margin-top: 30px;
    flex-direction: column;

    & + .v-list {
      margin-top: 0;
    }

    .v-avatar {
      width: 70px !important;
      height: 70px !important;
      margin-bottom: 40px;
      font-size: 30px;
    }
  }
}
</style>