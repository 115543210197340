<script setup>
import {useUserStore} from "@/stores/user";
import BildapButton from "../../components/BildapButton.vue";
import BildapBox from "../../components/BildapBox.vue";
import {storeToRefs} from "pinia";
import {
  PROJECT_CALCULATED_VALUES,
  PROJECT_CATEGORY,
  PROJECT_PRODUCT_FIELDS,
  PROJECT_QUOTA_FIELDS,
  PROJECT_STATUS,
  PROJECTS_FIELDS
} from "@/utilities/enums";
import PageHeader from "@/components/PageHeader.vue";
import Quote from "@/components/Quote.vue";
import ProjectCard from "@/components/ProjectCard.vue";
import ImageLoader from "@/components/ImageLoader.vue";
import PreviewCard from "@/components/PreviewCard.vue";
import {computed, onMounted, reactive, ref} from "vue";
import {EndpointsFactory} from "@/utilities/endpoints";
import {WorkerResponseCode} from "@/utilities/workerUtilites";
import {Swiper, SwiperSlide} from "swiper/vue";
import {register} from "swiper/element";
import {Navigation} from "swiper/modules";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import {useDomStore} from "@/stores/dom";
import {domIntToLabel} from "@/utilities/utilities";

const userStore = useUserStore();
const {isLoggedIn} = storeToRefs(userStore);
const domStore = useDomStore();
const {projectRates} = storeToRefs(domStore);

const pageLabelPrefix = 'home.';

register();
const swiperModules = [Navigation];

const homeProject = ref(null);
const homeDefaultQuota = computed(() => {
  if(!homeProject.value || homeProject.value[PROJECTS_FIELDS.category] !== PROJECT_CATEGORY.EQUITY){
    return null;
  }
  return homeProject.value[PROJECTS_FIELDS.quotas].find(q => q[PROJECT_QUOTA_FIELDS.id] === homeProject.value[PROJECTS_FIELDS.default_quota_id]);
});
const homeDefaultProduct = computed(() => {
  if(!homeProject.value || homeProject.value[PROJECTS_FIELDS.category] !== PROJECT_CATEGORY.DEBT){
    return null;
  }
  return homeProject.value[PROJECTS_FIELDS.products].find(q => q[PROJECT_PRODUCT_FIELDS.id] === homeProject.value[PROJECTS_FIELDS.default_product_id]);
});
const homeProjectRate = computed(() => homeDefaultProduct.value ? domIntToLabel(projectRates.value, Number(homeDefaultProduct.value[PROJECT_PRODUCT_FIELDS.rate_type])) : '');

const liquidatedProjects = reactive([]);

onMounted(() => {
  EndpointsFactory.execApiViaWorker({
    url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_HOMEPAGE_PROJECTS),
    method: 'GET'
  }, WorkerResponseCode.GET_HOMEPAGE_PROJECTS, ({result}) => {
    if(result.length){
      homeProject.value = result.pop();
    }
  }, () => console.error("error")); //Todo capire meglio che fare
  EndpointsFactory.execApiViaWorker({
    url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_PROJECTS_WITH_STATUS, {status_id: PROJECT_STATUS.LIQUIDATED}),
    method: 'GET'
  }, WorkerResponseCode.GET_PROJECTS_WITH_STATUS + PROJECT_STATUS.LIQUIDATED, ({result}) => {
    liquidatedProjects.length = 0;
    liquidatedProjects.push(...result);
  }, () => console.error("error")); //Todo capire meglio che fare
});
</script>

<template>
  <page-header :subtitle="$t(pageLabelPrefix + 'header_subtitle')" :title="$t(pageLabelPrefix + 'header_title')"
               img="/header/head-home.jpg">
    <BildapButton v-if="!isLoggedIn" :label="$t(pageLabelPrefix + 'header_signup')" class="btn-head"
                  to="/account/signup"></BildapButton>
  </page-header>
  <div class="page-content">
    <v-container>
      <v-row>
        <quote :subtitle="$t(pageLabelPrefix + 'section1_subtitle')"
               :text="$t(pageLabelPrefix + 'section1_title')"></quote>
      </v-row>
      <div class="wrapper-cards">
        <BildapBox :text="$t(pageLabelPrefix + 'box_content_1')"
                   :title="$t(pageLabelPrefix + 'box_title_1')" img="/home/1-expertise.jpg"></BildapBox>
        <BildapBox :text="$t(pageLabelPrefix + 'box_content_2')"
                   :title="$t(pageLabelPrefix + 'box_title_2')" img="/home/2-strumenti-tutela.jpg"></BildapBox>
        <BildapBox :text="$t(pageLabelPrefix + 'box_content_3')"
                   :title="$t(pageLabelPrefix + 'box_title_3')" img="/home/3-esc.jpg"></BildapBox>
        <BildapBox :text="$t(pageLabelPrefix + 'box_content_4')"
                   :title="$t(pageLabelPrefix + 'box_title_4')" img="/home/4-trasparenza.jpg"></BildapBox>
      </div>
      <div class="more-row">
        <div class="empty-colored-row"></div>
        <v-btn class="b-secondary" to="/how-it-works">{{ $t(pageLabelPrefix + 'find_more') }}</v-btn>
      </div>
    </v-container>

    <v-container class="box-margin-bottom">
      <v-row class="row-prog-home">
        <v-col cols="12" lg="6">
          <template v-if="homeProject && (homeDefaultQuota || homeDefaultProduct)">
            <ProjectCard v-if="homeProject[PROJECTS_FIELDS.category]===PROJECT_CATEGORY.EQUITY"
                         :id="homeProject[PROJECTS_FIELDS.id]" :category-type="homeProject[PROJECTS_FIELDS.category]"
                         :current-funds="homeProject[PROJECTS_FIELDS.offer_calculated_values][PROJECT_CALCULATED_VALUES.raised_funds]"
                         :duration="homeProject[PROJECTS_FIELDS.duration] ?? ''"
                         :funding-status="homeProject[PROJECTS_FIELDS.status]"
                         :img="homeProject[PROJECTS_FIELDS.card_img_id]" :title="homeProject[PROJECTS_FIELDS.title]"
                         :irr="homeDefaultQuota[PROJECT_QUOTA_FIELDS.irr]/100"
                         :location="homeProject[PROJECTS_FIELDS.city]"
                         :roi_final="homeProject[PROJECTS_FIELDS.final_roi]/100"
                         :roi="homeDefaultQuota[PROJECT_QUOTA_FIELDS.roi]/100"
                         :target="homeProject[PROJECTS_FIELDS.target_min]"
                         :type="homeProject[PROJECTS_FIELDS.type] ?? ''"></ProjectCard>
            <ProjectCard v-else :id="homeProject[PROJECTS_FIELDS.id]"
                         :category-type="homeProject[PROJECTS_FIELDS.category]"
                         :current-funds="homeProject[PROJECTS_FIELDS.offer_calculated_values][PROJECT_CALCULATED_VALUES.raised_funds]"
                         :duration="homeProject[PROJECTS_FIELDS.duration] ?? ''"
                         :funding-status="homeProject[PROJECTS_FIELDS.status]"
                         :guarantees="homeProject[PROJECTS_FIELDS.warranty]"
                         :final_tai="homeProject[PROJECTS_FIELDS.final_tai]/100"
                         :img="homeProject[PROJECTS_FIELDS.card_img_id]" :rate="homeProjectRate"
                         :tai="homeDefaultProduct[PROJECT_PRODUCT_FIELDS.tai]/100"
                         :target="homeProject[PROJECTS_FIELDS.target_min]" :title="homeProject[PROJECTS_FIELDS.title]"
                         :type="homeProject[PROJECTS_FIELDS.product_type] ?? ''"></ProjectCard>
          </template>
        </v-col>
        <v-col class="center-inner" cols="12" lg="6">
          <div class="box-simple-btn v-center">
            <h1 class="box-simple-title gen-title">{{ $t(pageLabelPrefix + 'section2_title') }}</h1>
            <div class="box-simple-text" v-html="$t(pageLabelPrefix + 'section2_subtitle_line1')"></div>
            <div class="box-simple-text" v-html="$t(pageLabelPrefix + 'section2_subtitle_line2')"></div>
            <v-btn class="b-secondary" to="/all-projects">{{ $t(pageLabelPrefix + 'projects') }}</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>

  <div :style="{backgroundImage:`url('/home/il-club.jpg')`}" class="section-promotion">
    <div class="card-text">
      <h1 class="c-title gen-title">{{ $t(pageLabelPrefix + 'section3_title') }}</h1>
      <div class="c-text" v-html="$t(pageLabelPrefix + 'section3_subtitle_line1')"></div>
    </div>
  </div>

  <div v-show="liquidatedProjects.length" class="page-content margin-bottom-30">
    <v-container>
      <h1 class="gen-title">{{ $t(pageLabelPrefix + 'section4_title') }}</h1>
    </v-container>
  </div>
  <v-container class="slider-section">
    <swiper :lazy="true" :modules="swiperModules"
            :navigation="{nextEl: '#swiper-button-next-unique', prevEl: '#swiper-button-prev-unique'}"
            :slidesPerView="'auto'" :spaceBetween="30" class="list-small-img-project">
      <swiper-slide v-for="project in liquidatedProjects" :key="project[PROJECTS_FIELDS.id]">
        <PreviewCard :id="project[PROJECTS_FIELDS.id]" :img="project[PROJECTS_FIELDS.card_img_id]"
                     :tag="$t('projects.status.ended')"
                     :title="project[PROJECTS_FIELDS.title]" class="slide-card"></PreviewCard>
      </swiper-slide>
      <div id="swiper-button-prev-unique" class="nav-btn btn-prev"><i class="mdi mdi-chevron-left"></i></div>
      <div id="swiper-button-next-unique" class="nav-btn btn-next"><i class="mdi mdi-chevron-right"></i></div>
    </swiper>
  </v-container>

  <v-container v-if="!isLoggedIn">
    <div class="simple-invite-card">
      <div class="wrapper-info-card">
        <h1 class="gen-title">{{ $t(pageLabelPrefix + 'section5_title') }}</h1>
        <span class="subtitle-card"></span>
        <h2 class="text-card">{{ $t(pageLabelPrefix + 'section5_subtitle') }}</h2>
        <div>
          <BildapButton :label="$t(pageLabelPrefix + 'header_signup')" to="/account/signup"></BildapButton>
        </div>
      </div>
      <div class="wrapper-img-card">
        <v-img class="img-card" cover src="/home/entra-club.jpg">
          <template v-slot:placeholder>
            <image-loader></image-loader>
          </template>
        </v-img>
      </div>
    </div>
  </v-container>
</template>

<style lang="less" scoped>
.box-margin-bottom {
  margin-bottom: 80px;
}

.row-prog-home {
  column-gap: 40px;
  row-gap: 40px;
  flex-wrap: nowrap;
  margin: 0;

  .v-col-12 {
    padding: 0;
  }
}

.nav-btn {
  display: none;
}

//MOBILE
.mobile-app {
  .row-prog-home {
    flex-wrap: wrap;
  }
}
</style>