import {computed, ref} from 'vue';
import {defineStore} from 'pinia';
import {USER_FIELDS, USER_STATUS, USER_TYPES} from "@/utilities/enums";
import {EndpointsFactory} from "@/utilities/endpoints";
import {DecodeResponseType, WorkerResponseCode} from "@/utilities/workerUtilites";
import {useDomStore} from "@/stores/dom";
import * as Sentry from "@sentry/vue";
import {useTextStore} from "@/stores/text";

export const useUserStore = defineStore('user', () => {
	const currentUser = ref(null);
	const currentFund = ref(null);
	const isLoggedIn = computed(() => !!currentUser.value);
	const isVerified = computed(() => currentUser.value && currentUser.value[USER_FIELDS.status] !== USER_STATUS.UNVERIFIED && currentUser.value[USER_FIELDS.status] !== USER_STATUS.DELETED);
	const fullName = computed(() => !currentUser.value ? '' : (currentUser.value[USER_FIELDS.type] === USER_TYPES.PRIVATE || currentUser.value[USER_FIELDS.type] === USER_TYPES.ADMIN ? currentUser.value[USER_FIELDS.name] + " " + currentUser.value[USER_FIELDS.surname] : currentUser.value[USER_FIELDS.business_name]));
	const initials = computed(() => {
		if(!currentUser.value){
			return '';
		}
		if(currentUser.value[USER_FIELDS.type] === USER_TYPES.PRIVATE || currentUser.value[USER_FIELDS.type] === USER_TYPES.ADMIN){
			return currentUser.value[USER_FIELDS.name].substring(0, 1) + currentUser.value[USER_FIELDS.surname].substring(0, 1);
		}
		return currentUser.value[USER_FIELDS.business_name].substring(0, 2);
	});
	const currentToken = computed(() => currentUser.value ? currentUser.value[USER_FIELDS.token] : null);
	const isAdmin = computed(() => currentUser.value && (currentUser.value[USER_FIELDS.type] === USER_TYPES.ADMIN));
	const userCanEditOwnProfile = computed(() => {
		if(!currentUser.value){
			return false;
		}
		if(isAdmin.value){
			return true;
		}
		return currentUser.value[USER_FIELDS.status] === USER_STATUS.VERIFIED || currentUser.value[USER_FIELDS.status] === USER_STATUS.WATING;

	});
	const userCanInvest = computed(() => {
		if(!currentUser.value){
			return false;
		}
		if(isAdmin.value){
			return true;
		}
		return currentUser.value[USER_FIELDS.status] === USER_STATUS.ACTIVE;
	});

	function setUser(userData){
		if(!import.meta.env.DEV){
			Sentry.setUser({
				email: userData ? userData[USER_FIELDS.email] : null,
				id: userData ? userData[USER_FIELDS.id] : null
			});
		}
		currentUser.value = userData;
	}

	function logout(tokenIsExpired = false){
		if(!import.meta.env.DEV){
			Sentry.setUser(null);
		}
		return new Promise(resolve => {
			const $router = this.router;
			const onEnd = () => {
				useDomStore().reset();
				useTextStore().reset();
				currentUser.value = null;
				resolve();
			};

			$router.push('/').then(() => {
				if(tokenIsExpired || !currentUser.value){
					onEnd();
					return;
				}
				EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.LOGOUT, {user_id: currentUser.value[USER_FIELDS.id]}),
					token: currentToken.value,
					method: 'DELETE',
					decodeResponseType: DecodeResponseType.NOTHING
				}, WorkerResponseCode.LOGOUT, onEnd, onEnd);
			});
		});
	}

	function updateUser(userData){
		currentUser.value = userData;
	}

	function setCurrentFund(fund){
		currentFund.value = fund;
	}

	function unsetCurrentFund(){
		currentFund.value = null;
	}

	return {
		currentUser,
		isLoggedIn,
		setUser,
		logout,
		fullName,
		initials,
		currentToken,
		updateUser,
		isAdmin,
		isVerified,
		userCanEditOwnProfile,
		userCanInvest,

		currentFund,
		setCurrentFund,
		unsetCurrentFund
	}
}, {
	persist: true
});
