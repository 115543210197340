<script setup>
import {computed, getCurrentInstance} from "vue";
import {PROJECT_CATEGORY, PROJECT_STATUS} from "../utilities/enums";
import BildapInfoTable from "@/components/BildapInfoTable.vue";
import {useI18n} from "vue-i18n";
import BildapMedia from "@/components/BildapMedia.vue";
import {useDomStore} from "@/stores/dom";
import {storeToRefs} from "pinia";
import {domIntToLabel} from "@/utilities/utilities";

const props = defineProps({
  fundingStatus: {
    type: Number,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  location: {
    type: Number
  },
  img: {
    type: Number
  },
  irr: {
    type: Number
  },
  type: {
    type: String,
    required: true
  },
  duration: {
    type: [String, Number],
    required: true
  },
  target: {
    type: Number,
    required: true
  },
  roi: {
    type: Number,
    required: false
  },
  roi_final: {
    type: Number,
    required: false
  },
  currentFunds: {
    type: Number
  },
  guarantees: {
    type: String
  },
  tai: {
    type: Number
  },
  final_tai: {
    type: Number
  },
  rate: {
    type: String
  },
  categoryType: {
    type: Number,
    required: true
  },
  id: {
    type: Number,
    required: true
  }
});

const {t: $t} = useI18n();
const {proxy} = getCurrentInstance();
const domStore = useDomStore();
const {projectStatuses, cities} = storeToRefs(domStore);

const currentPerc = computed(() => {
  const perc = props.currentFunds * 100 / props.target;
  if(perc > 100){
    return 100;
  }
  return perc.toFixed(0);
});

const currentPercLabel = computed(() => {
  return (props.currentFunds * 100 / props.target).toFixed(0);
});

const statusLabel = computed(() => domIntToLabel(projectStatuses.value, props.fundingStatus));

const infoTableItems = computed(() => {
  const res = [];
  if(props.categoryType === PROJECT_CATEGORY.EQUITY){
    res.push({
      label: $t('projects.expected_irr'),
      value: proxy.$filters.formatPercentage(props.irr)
    });
  }
  res.push({
    label: $t('projects.type'),
    value: props.type
  });
  if(props.categoryType === PROJECT_CATEGORY.DEBT){
    res.push({
          label: $t('projects.rate_type'),
          value: props.rate
        },
        {
          label: $t('projects.guarantees'),
          value: props.guarantees
        });
  }
  if(props.fundingStatus !== PROJECT_STATUS.READY){
    res.push({
      label: $t('projects.funded'),
      labelClass: 'bold big-text',
      value: proxy.$filters.formatCurrency(props.currentFunds),
      valueClass: 'bold big-text'
    });
  }
  return res;
});
</script>

<template>
  <div class="wrapper-pcb wrapper-card">
    <span v-if="statusLabel" class="status" v-text="statusLabel"></span>
    <v-card :to="'/project/'+id" class="project-card-big" rounded="xl">
      <bildap-media :media-id="img" class="card-img" img-aspect-ratio="16/19" img-cover
                    max-height="300px" height="300px"></bildap-media>
      <div class="inner-pcb">
        <v-card-title class="pcb-title" v-text="domIntToLabel(cities, location)"></v-card-title>
        <v-card-title class="pcb-indirizzo" v-text="title"></v-card-title>
        <v-card-text>
          <div class="subtitle-founded">
            <template
                v-if="fundingStatus === PROJECT_STATUS.LIQUIDATED">
              <span v-if="categoryType === PROJECT_CATEGORY.EQUITY">{{
                  $t('projects.final_roi', {value: $filters.formatPercentage(roi_final)})
                }}</span>
              <span v-else>{{ $t('projects.final_tai') }}: {{ $filters.formatPercentage(final_tai) }}</span> -
            <span>{{ $t('projects.duration') + ' ' + $t('projects.duration_months', duration) }}</span>
            </template>
          </div>

          <bildap-info-table :items="infoTableItems"></bildap-info-table>

          <div v-if="fundingStatus !== PROJECT_STATUS.READY" class="wrapper-progress-bar">
            <div :class="(currentPerc>99)?'big-perc':''" :style="{left:currentPerc+ '%'}" class="wrapper-cur-perc">
              <div class="cur-perc">{{ currentPercLabel }}%</div>
            </div>
            <v-progress-linear v-model="currentPerc" :height="6" class="progress-bar" rounded></v-progress-linear>
          </div>

          <v-container>
            <table class="table-pcb">
              <tr class="header">
                <td>{{ $t('projects.expected_duration') }}</td>
                <td>{{ $t('projects.target') }}</td>
                <td v-if="categoryType === PROJECT_CATEGORY.EQUITY">{{ $t('projects.expected_roi') }}</td>
                <td v-else>{{ $t('projects.tai') }}</td>
              </tr>
              <tr class="t-row">
                <td>{{ $t('projects.duration_months', duration) }}</td>
                <td>{{ $filters.formatCurrency(target) }}</td>
                <td v-if="categoryType === PROJECT_CATEGORY.EQUITY">{{ $filters.formatPercentage(roi) }}</td>
                <td v-else>{{ $filters.formatPercentage(tai) }}</td>
              </tr>
            </table>
          </v-container>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<style lang="less" scoped>
.project-card-big {
  .set-box-shadow();
  color: @font-color;

  //box interno con tutti i dati ma non l'immagine
  .inner-pcb {
    padding: 0 10px 10px 10px;
  }
}

.pcb-title {
  color: @grey;
  font-weight: 300;
  text-transform: uppercase;
  padding-bottom: 0;
  white-space: normal;
}

.pcb-indirizzo {
  padding-top: 0;
  font-weight: 500;
  font-size: 30px;
  white-space: normal;
}

//sottotitolo solo se la card è founded
.subtitle-founded {
  color: @font-primary-color;
  font-size: 17px;
  min-height: 20px;
}

.card-img{
  height: 300px;
}

//tabella in basso al box
@radius-table: 15px;
.table-pcb {
  width: 100%;
  font-size: 15px;
  font-weight: 500;

  td {
    border-left: 2px solid @white;
    border-collapse: collapse;
    padding: 10px;

    &:first-child {
      border-left: 0;
    }
  }

  .header {
    td {
      background: @secondary-color;
      color: @white;
      text-align: center;
      text-transform: uppercase;
      width: 33%;

      &:first-child {
        .set-border-radius-top-left(@radius-table);
      }

      &:last-child {
        .set-border-radius-top-right(@radius-table);
      }
    }
  }

  .t-row {
    td {
      background: @light-grey;
      text-align: center;

      &:first-child {
        .set-border-radius-bottom-left(@radius-table);
      }

      &:last-child {
        .set-border-radius-bottom-right(@radius-table);
      }
    }
  }
}

//STILE MOBILE
.mobile-main {

  .card-img{
    height: auto;
  }

  .table-pcb {
    font-size: 12px;
    font-weight: 400;
    width: calc(100% + 30px);
    margin-right: -15px;
    margin-left: -15px;

    .header{
      td{
        padding: 5px;
      }
    }
  }

  .pcb-title {
    font-size: 18px;
  }

  .pcb-indirizzo {
    font-size: 25px;
    font-weight: 400;
  }

  .status {
    font-size: 14px;
    right: 40px;
  }
}
</style>