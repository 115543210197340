import {computed, reactive} from 'vue';
import {defineStore} from 'pinia';
import {EndpointsFactory} from "@/utilities/endpoints";
import {WorkerResponseCode} from "@/utilities/workerUtilites";
import {useAppStore} from "@/stores/app";

export const useDomStore = defineStore('dom', () => {
	const serverData = reactive({
		serverCountryLabels: {},
		serverCountries: [],
		serverGenderLabels: {},
		serverGenders: [],
		serverCities: [],
		serverStates: [],
		serverDocumentTypeLabels: {},
		serverDocumentTypes: [],
		serverBusinessAreaLabels: {},
		serverBusinessAreas: [],
		serverInvestorTypeLabels: {},
		serverInvestorTypes: [],
		serverSpvStatusLabels: {},
		serverSpvStatuses: [],
		serverProfileStatusLabels: {},
		serverProfileStatuses: [],
		serverSpvFundsSourceLabels: {},
		serverSpvFundsSources: [],
		serverProjectRateLabels: {},
		serverProjectRates: [],
		serverProjectStatusLabels: {},
		serverProjectStatuses: [],
		serverProjectRiskProfileLabels: {},
		serverProjectRiskProfiles: [],
		serverProjectUpdateStatusLabels: {},
		serverProjectUpdateStatuses: [],
		serverProjectOrderStatusLabels: {},
		serverProjectOrderStatuses: [],
		serverOrderErrorLabels: {},
		serverOrderErrors: [],
		serverProposalStatusLabels: {},
		serverProposalStatuses: [],
		serverPropertyTypeLabels: {},
		serverPropertyTypes: [],
		serverDurationLabels: {},
		serverDurations: [],
		serverPurposeLabels: {},
		serverPurposes: []
	});

	const countries = computed(() => {
		return serverData.serverCountries.map(c => {
			return {
				value: c[DOM_COUNTRIES_FIELDS.ID],
				title: serverData.serverCountryLabels[c[DOM_COUNTRIES_FIELDS.NAME]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const nationalities = computed(() => {
		return serverData.serverCountries.map(c => {
			return {
				value: c[DOM_COUNTRIES_FIELDS.ID],
				title: serverData.serverCountryLabels[c[DOM_COUNTRIES_FIELDS.CITIZENSHIP]]
			};
		});
	});
	const genders = computed(() => {
		return serverData.serverGenders.map(c => {
			return {value: c[DOM_GENDERS_FIELDS.ID], title: serverData.serverGenderLabels[c[DOM_GENDERS_FIELDS.NAME]]};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const cities = computed(() => {
		return serverData.serverCities.map(c => {
			return {value: c[DOM_CITIES_FIELDS.ID], title: c[DOM_CITIES_FIELDS.NAME]};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const states = computed(() => {
		return serverData.serverStates.map(c => {
			return {value: c[DOM_STATES_FIELDS.CODE], title: c[DOM_STATES_FIELDS.NAME]};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const documentTypes = computed(() => {
		return serverData.serverDocumentTypes.map(c => {
			return {
				value: c[DOM_DOCUMENT_TYPES_FIELDS.ID],
				title: serverData.serverDocumentTypeLabels[c[DOM_DOCUMENT_TYPES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const businessAreas = computed(() => {
		return serverData.serverBusinessAreas.map(c => {
			return {
				value: c[DOM_BUSINESS_AREAS_FIELDS.ID],
				title: serverData.serverBusinessAreaLabels[c[DOM_BUSINESS_AREAS_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const investorTypes = computed(() => {
		return serverData.serverInvestorTypes.map(c => {
			return {
				value: c[DOM_INVESTOR_TYPES_FIELDS.ID],
				title: serverData.serverInvestorTypeLabels[c[DOM_INVESTOR_TYPES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const spvStatuses = computed(() => {
		return serverData.serverSpvStatuses.map(c => {
			return {
				value: c[DOM_SPV_STATUSES_FIELDS.ID],
				title: serverData.serverSpvStatusLabels[c[DOM_SPV_STATUSES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const spvFundsSources = computed(() => {
		return serverData.serverSpvFundsSources.map(c => {
			return {
				value: c[DOM_SPV_FUNDS_SOURCES_FIELDS.ID],
				title: serverData.serverSpvFundsSourceLabels[c[DOM_SPV_FUNDS_SOURCES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const profileStatuses = computed(() => {
		return serverData.serverProfileStatuses.map(c => {
			return {
				value: c[DOM_PROFILE_STATUSES_FIELDS.ID],
				title: serverData.serverProfileStatusLabels[c[DOM_PROFILE_STATUSES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const projectRates = computed(() => {
		return serverData.serverProjectRates.map(c => {
			return {
				value: c[DOM_PROJECT_RATES_FIELDS.ID],
				title: serverData.serverProjectRateLabels[c[DOM_PROJECT_RATES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const projectStatuses = computed(() => {
		return serverData.serverProjectStatuses.map(c => {
			return {
				value: c[DOM_PROJECT_STATUSES_FIELDS.ID],
				title: serverData.serverProjectStatusLabels[c[DOM_PROJECT_STATUSES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const projectRiskProfiles = computed(() => {
		return serverData.serverProjectRiskProfiles.map(c => {
			return {
				value: c[DOM_PROJECT_RISK_PROFILES_FIELDS.ID],
				title: serverData.serverProjectRiskProfileLabels[c[DOM_PROJECT_RISK_PROFILES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const projectUpdateStatuses = computed(() => {
		return serverData.serverProjectUpdateStatuses.map(c => {
			return {
				value: c[DOM_PROJECT_UPDATE_STATUSES_FIELDS.ID],
				title: serverData.serverProjectUpdateStatusLabels[c[DOM_PROJECT_UPDATE_STATUSES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const projectOrderStatuses = computed(() => {
		return serverData.serverProjectOrderStatuses.map(c => {
			return {
				value: c[DOM_PROJECT_ORDER_STATUSES_FIELDS.ID],
				title: serverData.serverProjectOrderStatusLabels[c[DOM_PROJECT_ORDER_STATUSES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const orderErrors = computed(() => {
		return serverData.serverOrderErrors.map(c => {
			return {
				value: c[DOM_ORDER_ERRORS_FIELDS.ID],
				title: serverData.serverOrderErrorLabels[c[DOM_ORDER_ERRORS_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const proposalStatuses = computed(() => {
		return serverData.serverProposalStatuses.map(c => {
			return {
				value: c[DOM_PROPOSAL_STATUSES_FIELDS.ID],
				title: serverData.serverProposalStatusLabels[c[DOM_PROPOSAL_STATUSES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const propertyTypes = computed(() => {
		return serverData.serverPropertyTypes.map(c => {
			return {
				value: c[DOM_PROPERTY_TYPES_FIELDS.ID],
				title: serverData.serverPropertyTypeLabels[c[DOM_PROPERTY_TYPES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const durations = computed(() => {
		return serverData.serverDurations.map(c => {
			return {
				value: c[DOM_DURATIONS_FIELDS.ID],
				title: serverData.serverDurationLabels[c[DOM_DURATIONS_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});
	const purposes = computed(() => {
		return serverData.serverPurposes.map(c => {
			return {
				value: c[DOM_PURPOSES_FIELDS.ID],
				title: serverData.serverPurposeLabels[c[DOM_PURPOSES_FIELDS.LBL_KEY]]
			};
		}).sort((c1, c2) => {
			return c1.title ? c1.title.localeCompare(c2.title) : c2;
		});
	});

	function reset(){
		serverData.serverCountryLabels = {};
		serverData.serverCountries = [];
		serverData.serverGenderLabels = {};
		serverData.serverGenders = [];
		serverData.serverCities = [];
		serverData.serverStates = [];
		serverData.serverDocumentTypeLabels = {};
		serverData.serverDocumentTypes = [];
		serverData.serverBusinessAreaLabels = {};
		serverData.serverBusinessAreas = [];
		serverData.serverInvestorTypeLabels = {};
		serverData.serverInvestorTypes = [];
		serverData.serverSpvStatusLabels = {};
		serverData.serverSpvStatuses = [];
		serverData.serverProfileStatusLabels = {};
		serverData.serverProfileStatuses = [];
		serverData.serverSpvFundsSourceLabels = {};
		serverData.serverSpvFundsSources = [];
		serverData.serverProjectRateLabels = {};
		serverData.serverProjectRates = [];
		serverData.serverProjectStatusLabels = {};
		serverData.serverProjectStatuses = [];
		serverData.serverProjectRiskProfileLabels = {};
		serverData.serverProjectRiskProfiles = [];
		serverData.serverProjectUpdateStatusLabels = {};
		serverData.serverProjectUpdateStatuses = [];
		serverData.serverProjectOrderStatusLabels = {};
		serverData.serverProjectOrderStatuses = [];
		serverData.serverOrderErrorLabels = {};
		serverData.serverOrderErrors = [];
		serverData.serverProposalStatusLabels = {};
		serverData.serverProposalStatuses = [];
		serverData.serverPropertyTypeLabels = {};
		serverData.serverPropertyTypes = [];
		serverData.serverDurationLabels = {};
		serverData.serverDurations = [];
		serverData.serverPurposeLabels = {};
		serverData.serverPurposes = [];
	}

	function _fetchCountriesLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverCountryLabels && Object.keys(serverData.serverCountryLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_countries"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_COUNTRIES_LABELS, ({result}) => {
				serverData.serverCountryLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchCountries(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverCountries && serverData.serverCountries.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_COUNTRIES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_COUNTRIES, ({result}) => {
					serverData.serverCountries = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchGendersLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverGenderLabels && Object.keys(serverData.serverGenderLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_genders"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_GENDERS_LABELS, ({result}) => {
				serverData.serverGenderLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchGenders(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverGenders && serverData.serverGenders.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_GENDERS),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_GENDERS, ({result}) => {
					serverData.serverGenders = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchDocumentTypesLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverDocumentTypeLabels && Object.keys(serverData.serverDocumentTypeLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_document_types"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_DOCUMENT_TYPES_LABELS, ({result}) => {
				serverData.serverDocumentTypeLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchDocumentTypes(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverDocumentTypes && serverData.serverDocumentTypes.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_DOCUMENT_TYPES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_DOCUMENT_TYPES, ({result}) => {
					serverData.serverDocumentTypes = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchBusinessAreaLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverBusinessAreaLabels && Object.keys(serverData.serverBusinessAreaLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_business_areas"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_BUSINESS_AREAS_LABELS, ({result}) => {
				serverData.serverBusinessAreaLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchBusinessAreas(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverBusinessAreas && serverData.serverBusinessAreas.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_BUSINESS_AREAS),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_BUSINESS_AREAS, ({result}) => {
					serverData.serverBusinessAreas = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchCities(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverCities && serverData.serverCities.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_CITIES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_CITIES, ({result}) => {
					serverData.serverCities = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchStates(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverStates && serverData.serverStates.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_STATES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_STATES, ({result}) => {
					serverData.serverStates = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchInvestorTypeLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverInvestorTypeLabels && Object.keys(serverData.serverInvestorTypeLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_user_investor_types"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_INVESTOR_TYPES_LABELS, ({result}) => {
				serverData.serverInvestorTypeLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchInvestorTypes(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverInvestorTypes && serverData.serverInvestorTypes.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_INVESTOR_TYPES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_INVESTOR_TYPES, ({result}) => {
					serverData.serverInvestorTypes = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchSpvStatusLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverSpvStatusLabels && Object.keys(serverData.serverSpvStatusLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_spv_statuses"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_SPV_STATUSES_LABELS, ({result}) => {
				serverData.serverSpvStatusLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchSpvStatuses(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverSpvStatuses && serverData.serverSpvStatuses.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_SPV_STATUSES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_SPV_STATUSES, ({result}) => {
					serverData.serverSpvStatuses = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchSpvFundsSourceLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverSpvFundsSourceLabels && Object.keys(serverData.serverSpvFundsSourceLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_spv_fund_channels"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_SPV_FUNDS_SOURCES_LABELS, ({result}) => {
				serverData.serverSpvFundsSourceLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchSpvFundsSources(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverSpvFundsSources && serverData.serverSpvFundsSources.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_SPV_FUNDS_SOURCES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_SPV_FUNDS_SOURCES, ({result}) => {
					serverData.serverSpvFundsSources = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchProfileStatusLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverProfileStatusLabels && Object.keys(serverData.serverProfileStatusLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_statuses"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_PROFILE_STATUSES_LABELS, ({result}) => {
				serverData.serverProfileStatusLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchProfileStatuses(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverProfileStatuses && serverData.serverProfileStatuses.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_PROFILE_STATUSES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_PROFILE_STATUSES, ({result}) => {
					serverData.serverProfileStatuses = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchProjectRateLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverProjectRateLabels && Object.keys(serverData.serverProjectRateLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_offer_rate_types"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_PROJECT_RATES_LABELS, ({result}) => {
				serverData.serverProjectRateLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchProjectRates(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverProjectRates && serverData.serverProjectRates.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_PROJECT_RATES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_PROJECT_RATES, ({result}) => {
					serverData.serverProjectRates = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchProjectStatusLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverProjectStatusLabels && Object.keys(serverData.serverProjectStatusLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_offer_status"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_PROJECT_STATUSES_LABELS, ({result}) => {
				serverData.serverProjectStatusLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchProjectStatuses(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverProjectStatuses && serverData.serverProjectStatuses.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_PROJECT_STATUSES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_PROJECT_STATUSES, ({result}) => {
					serverData.serverProjectStatuses = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchProjectRiskProfileLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverProjectRiskProfileLabels && Object.keys(serverData.serverProjectRiskProfileLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_offer_risk_profiles"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_PROJECT_RISK_PROFILES_LABELS, ({result}) => {
				serverData.serverProjectRiskProfileLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchProjectRiskProfiles(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverProjectRiskProfiles && serverData.serverProjectRiskProfiles.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_PROJECT_RISK_PROFILES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_PROJECT_RISK_PROFILES, ({result}) => {
					serverData.serverProjectRiskProfiles = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchProjectUpdateStatusLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverProjectUpdateStatusLabels && Object.keys(serverData.serverProjectUpdateStatusLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_offer_update_statuses"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_PROJECT_UPDATE_STATUSES_LABELS, ({result}) => {
				serverData.serverProjectUpdateStatusLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchProjectUpdateStatuses(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverProjectUpdateStatuses && serverData.serverProjectUpdateStatuses.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_PROJECT_UPDATE_STATUSES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_PROJECT_UPDATE_STATUSES, ({result}) => {
					serverData.serverProjectUpdateStatuses = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchProjectOrderStatusLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverProjectOrderStatusLabels && Object.keys(serverData.serverProjectOrderStatusLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_offer_order_statuses"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_PROJECT_ORDER_STATUSES_LABELS, ({result}) => {
				serverData.serverProjectOrderStatusLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchProjectOrderStatuses(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverProjectOrderStatuses && serverData.serverProjectOrderStatuses.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_PROJECT_ORDER_STATUSES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_PROJECT_ORDER_STATUSES, ({result}) => {
					serverData.serverProjectOrderStatuses = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchOrderErrorLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverOrderErrorLabels && Object.keys(serverData.serverOrderErrorLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_offer_order_errors"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_ORDER_ERRORS_LABELS, ({result}) => {
				serverData.serverOrderErrorLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchOrderErrors(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverOrderErrors && serverData.serverOrderErrors.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_ORDER_ERRORS),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_ORDER_ERRORS, ({result}) => {
					serverData.serverOrderErrors = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchProposalStatusLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverProposalStatusLabels && Object.keys(serverData.serverProposalStatusLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_proposal_statuses"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_PROPOSAL_STATUSES_LABELS, ({result}) => {
				serverData.serverProposalStatusLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchProposalStatuses(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverProposalStatuses && serverData.serverProposalStatuses.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_PROPOSAL_STATUSES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_PROPOSAL_STATUSES, ({result}) => {
					serverData.serverProposalStatuses = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchPropertyTypeLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverPropertyTypeLabels && Object.keys(serverData.serverPropertyTypeLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_property_types"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_PROPERTY_TYPES_LABELS, ({result}) => {
				serverData.serverPropertyTypeLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchPropertyTypes(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverPropertyTypes && serverData.serverPropertyTypes.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_PROPERTY_TYPES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_PROPERTY_TYPES, ({result}) => {
					serverData.serverPropertyTypes = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchDurationsLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverDurationLabels && Object.keys(serverData.serverDurationLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_project_duration"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_DURATIONS_LABELS, ({result}) => {
				serverData.serverDurationLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchDurations(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverDurations && serverData.serverDurations.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_DURATIONS),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_DURATIONS, ({result}) => {
					serverData.serverDurations = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	function _fetchPurposesLabels(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverPurposeLabels && Object.keys(serverData.serverPurposeLabels).length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_LABELS, {
					language_code: currentLang,
					labels_prefix: "dom_ta_property_purposes"
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_PURPOSES_LABELS, ({result}) => {
				serverData.serverPurposeLabels = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	function _fetchPurposes(currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.serverPurposes && serverData.serverPurposes.length){ //aggiorno solo se non le ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
					url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_PURPOSES),
					token: currentToken,
					method: 'GET'
				},
				WorkerResponseCode.GET_PURPOSES, ({result}) => {
					serverData.serverPurposes = result;
					resolve()
				}, () => {
					reject();
				});
		});
	}

	async function loadDomData(currentToken, currentLang, admin = false, guest = false){
		const appStore = useAppStore();
		appStore.showSpinner(true);
		try{
			if(guest){
				await Promise.all([_fetchCities(),
					_fetchProjectRates(), _fetchProjectRateLabels(currentLang),
					_fetchProjectStatuses(), _fetchProjectStatusLabels(currentLang),
					_fetchPropertyTypes(), _fetchPropertyTypeLabels(currentLang),
					_fetchDurations(), _fetchDurationsLabels(currentLang),
					_fetchPurposes(), _fetchPurposesLabels(currentLang)]);
			}
			else{
				await Promise.all([_fetchCountries(currentToken), _fetchCountriesLabels(currentLang, currentToken),
					_fetchGenders(currentToken), _fetchGendersLabels(currentLang, currentToken),
					_fetchCities(currentToken),
					_fetchDocumentTypes(currentToken), _fetchDocumentTypesLabels(currentLang, currentToken),
					_fetchInvestorTypes(currentToken), _fetchInvestorTypeLabels(currentLang, currentToken),
					_fetchOrderErrors(currentToken), _fetchOrderErrorLabels(currentLang, currentToken),
					_fetchProjectOrderStatuses(currentToken), _fetchProjectOrderStatusLabels(currentLang, currentToken)
				]);
				if(admin){
					await Promise.all([_fetchBusinessAreas(currentToken), _fetchBusinessAreaLabels(currentLang, currentToken),
						_fetchStates(currentToken),
						_fetchSpvStatuses(currentToken), _fetchSpvStatusLabels(currentLang, currentToken),
						_fetchSpvFundsSources(currentToken), _fetchSpvFundsSourceLabels(currentLang, currentToken),
						_fetchProfileStatuses(currentToken), _fetchProfileStatusLabels(currentLang, currentToken),
						_fetchProjectRates(currentToken), _fetchProjectRateLabels(currentLang, currentToken),
						_fetchProjectStatuses(currentToken), _fetchProjectStatusLabels(currentLang, currentToken),
						_fetchProjectRiskProfiles(currentToken), _fetchProjectRiskProfileLabels(currentLang, currentToken),
						_fetchProjectUpdateStatuses(currentToken), _fetchProjectUpdateStatusLabels(currentLang, currentToken),
						_fetchProposalStatuses(currentToken), _fetchProposalStatusLabels(currentLang, currentToken)
					]);
				}
			}
			appStore.hideSpinner(true);
		}catch (e){
			appStore.showError();
		}
	}

	return {
		countries,
		genders,
		cities,
		states,
		nationalities,
		documentTypes,
		businessAreas,
		investorTypes,
		spvStatuses,
		spvFundsSources,
		profileStatuses,
		projectRates,
		projectStatuses,
		projectRiskProfiles,
		projectUpdateStatuses,
		projectOrderStatuses,
		orderErrors,
		proposalStatuses,
		propertyTypes,
		durations,
		purposes,

		loadDomData,
		reset,
		//questo è esposto solo perché così viene salvato in locale; ma non è pensato per essere usato
		serverData
	};
}, {
	persist: true
});

const DOM_COUNTRIES_FIELDS = {
	ID: 'id',
	NAME: 'label_key_name',
	CITIZENSHIP: 'label_key_citizenship',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_GENDERS_FIELDS = {
	ID: 'id',
	NAME: 'label_key_name',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_CITIES_FIELDS = {
	ID: 'id',
	NAME: 'name',
	PROVINCE_CODE: 'province_code',
	PROVINCE: 'province',
	PROGRESSIVE: 'progressive',
	CODE: 'city_code',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_STATES_FIELDS = {
	CODE: 'code',
	NAME: 'name',
	PLATE: 'plate',
	cities: 'cities',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_DOCUMENT_TYPES_FIELDS = {
	ID: 'id',
	DOCUMENT: 'document',
	LBL_KEY: 'label_key_document_type',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_BUSINESS_AREAS_FIELDS = {
	ID: 'id',
	BUSINEESS_AREA: 'business_area',
	LBL_KEY: 'label_key_business_area',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_INVESTOR_TYPES_FIELDS = {
	ID: 'id',
	INVESTOR_TYPE: 'investor_type',
	LBL_KEY: 'label_key_investor_type',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_SPV_STATUSES_FIELDS = {
	ID: 'id',
	STATUS: 'status',
	LBL_KEY: 'label_key_spv_status',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_SPV_FUNDS_SOURCES_FIELDS = {
	ID: 'id',
	CHANNEL: 'channel',
	LBL_KEY: 'label_key_spv_fund_channel',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PROFILE_STATUSES_FIELDS = {
	ID: 'id',
	STATUS: 'status',
	LBL_KEY: 'label_key_profile_status',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PROJECT_RATES_FIELDS = {
	ID: 'id',
	RATE: 'rate_type',
	LBL_KEY: 'label_key_rate_type',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PROJECT_STATUSES_FIELDS = {
	ID: 'id',
	STATUS: 'status',
	LBL_KEY: 'label_key_offer_status',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PROJECT_RISK_PROFILES_FIELDS = {
	ID: 'id',
	RISK_PROFILE: 'risk_profile',
	LBL_KEY: 'label_key_risk_profile',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PROJECT_UPDATE_STATUSES_FIELDS = {
	ID: 'id',
	STATUS: 'status',
	LBL_KEY: 'label_key_update_status',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PROJECT_ORDER_STATUSES_FIELDS = {
	ID: 'id',
	STATUS: 'status',
	LBL_KEY: 'label_key_status',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_ORDER_ERRORS_FIELDS = {
	ID: 'id',
	ERROR: 'error',
	LBL_KEY: 'label_key_error',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PROPOSAL_STATUSES_FIELDS = {
	ID: 'id',
	STATUS: 'status',
	LBL_KEY: 'label_key_status',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PROPERTY_TYPES_FIELDS = {
	ID: 'id',
	PROPERTY_TYPE: 'property_type',
	LBL_KEY: 'label_key_property_type',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_DURATIONS_FIELDS = {
	ID: 'id',
	DURATION: 'project_duration',
	LBL_KEY: 'label_key_project_duration',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};

const DOM_PURPOSES_FIELDS = {
	ID: 'id',
	PURPOSE: 'property_purpose',
	LBL_KEY: 'label_key_property_purpose',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date'
};