<script setup>
import {useUserStore} from "@/stores/user";
import {storeToRefs} from "pinia";

defineProps({
  color: {
    type: String,
    default: 'grey-darken-1'
  },
  size: {
    type: [Number, String],
    default: 60
  }
});

const userStore = useUserStore();
const {initials} = storeToRefs(userStore);
</script>

<template>
  <v-avatar :color="color" :size="size" class="user-avatar">{{ initials }}</v-avatar>
</template>

<style lang="less" scoped>
.v-avatar{
  font-weight: 400;
  font-size: 25px;
  background: @primary-color !important;
  color: @font-color !important;
  cursor: pointer;
  text-transform: uppercase;
}
</style>