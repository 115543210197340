export function suitabilityResultLabel(score, incoherence, coercion){
	const prefix = 'dashboard.user.test.mifid.result_';
	if(incoherence){
		return score;
	}
	if(score < Number(import.meta.env.VITE_MIFID_MIN_SCORE_SUITABLE) || coercion){
		return prefix + 'ko';
	}
	return prefix + 'ok';
}

export function domIntToLabel(arrayDom, id){
	if(!arrayDom){
		return '';
	}
	const elem = arrayDom.find(s => s.value === id);
	return elem ? elem.title : '';
}

export function transformOptional(val, orig){
	return orig === "" || orig === null ? undefined : val;
}

export function booleanToLabel($t, val){
	return $t('global.' + (val ? 'yes' : 'no'));
}

export function isEmptyObj(obj){
	return !Object.keys(obj).length;
}

export function saveCsvFile(csvString, fileName = 'filename.csv'){
	const csvContent = csvString.startsWith('data:text/csv;') ? csvString : "data:text/csv;charset=utf-8," + csvString;
	const encodedUri = encodeURI(csvContent);
	const link = document.createElement("a");
	link.setAttribute("href", encodedUri);
	link.setAttribute("download", fileName);
	document.body.appendChild(link);
	link.click();
	link.remove();
}

export function datesDifferenceInDays(startDate, endDate){
	const diffInMs = new Date(endDate) - new Date(startDate)
	return Math.round(diffInMs / (1000 * 60 * 60 * 24));
}