<script setup>
import ImageLoader from "@/components/ImageLoader.vue";
import VuePdfEmbed from "vue-pdf-embed";
import {computed, onMounted, ref, watch} from "vue";
import {EndpointsFactory} from "@/utilities/endpoints";
import {DecodeResponseType, WorkerResponseCode} from "@/utilities/workerUtilites";
import {useAppStore} from "@/stores/app";
import mime from "mime/lite";

const props = defineProps({
  mediaId: {
    type: Number,
    required: true
  },
  extension: {
    type: String,
    default: ''
  },
  pdfHeight: {
    type: [Number, String],
    default: null
  },
  pdfPages: {
    type: Number,
    default: null
  },
  imgAspectRatio: {
    type: [String, Number],
    default: undefined
  },
  imgCover: {
    type: Boolean,
    default: false
  },
  useDiv: { //nel caso la preview con img sia investibile si può usare un div semplice
    type: Boolean,
    default: false
  },
  maxHeight: {
    type: [String, Number],
    default: undefined
  },
  maxWidth: {
    type: [String, Number],
    default: undefined
  },
  height: {
    type: [String, Number],
    default: undefined
  },
  showDownload: {
    type: Boolean,
    default: false
  },
  bigPreviewComplete: { //la preview grande per le immagini e se pdf fa scorrere il contenuto dentro la modale
    type: Boolean,
    default: false
  },
  isPreview: { //se serve che sia una preview con sfondo grigio e bordi
    type: Boolean,
    default: false
  },
  hidePreview: {
    type: Boolean,
    default: false
  },
  downloadBtnLabel: {
    type: String,
    default: ''
  },
  icon: { //icona del bottone di download
    type: String,
    default: ''
  },
  variantPlain: { //stile bottone download
    type: Boolean,
    default: false
  },
  previewAsLink: { //stile link download
    type: Boolean,
    default: false
  },
  filename: {
    type: String
  }
});

const emit = defineEmits(['update:mediaId']);

const currId = computed({
  get(){
    return props.mediaId;
  },
  set(currValue){
    emit('update:mediaId', currValue);
  }
});

const mediaUrl = ref('');
const loading = ref(true);
const defaultFilename = "download.";
const downloadAttribute = computed(() => {
  if(props.filename){
        return props.filename;
      }
  if(props.downloadBtnLabel){
    return props.downloadBtnLabel;
  }
  if(contentType.value === 'application/octet-stream'){ //webp arrivano come "application/octet-stream")
        return defaultFilename + "webp";
      }
      return defaultFilename + mime.getExtension(contentType.value);
    }
);

const appStore = useAppStore();

const contentType = ref('image/*');

const previewIsPdf = computed(() => {
  return contentType.value === 'application/pdf';
});
const previewIsImg = computed(() => {
  return contentType.value && (contentType.value.startsWith('image/') || contentType.value === 'application/octet-stream'); //webp arrivano come "application/octet-stream"
});
const previewIsVideo = computed(() => {
  return contentType.value && contentType.value.startsWith('video/');
});

watch(currId, newVal => loadPreview(newVal));

const showDownloadBtn = computed(() => props.showDownload || (!previewIsPdf.value && !previewIsImg.value && !previewIsVideo.value));

onMounted(() => loadPreview(currId.value));

function loadPreview(id){
  if(!id){
    return;
  }
  loading.value = true;
  EndpointsFactory.execApiViaWorker({
    url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_MEDIA, {media_id: id}),
    method: 'GET',
    decodeResponseType: DecodeResponseType.BLOB,
  }, WorkerResponseCode.GET_MEDIA + id, ({result, contentType: ct}) => {
    contentType.value = ct;
    mediaUrl.value = window.URL.createObjectURL(result);
    loading.value = false;
  }, () => appStore.showError());
}
</script>

<template>
  <div class="wrapper-media">
    <div v-if="!hidePreview" class="wrapper-loader">
      <v-skeleton-loader v-if="loading" type="image"></v-skeleton-loader>
      <template v-if="useDiv">
        <div v-if="previewIsImg && mediaUrl" v-show="!loading"
             :style="{backgroundImage:`url('`+mediaUrl+`')`, 'height':height, 'max-width':maxWidth, 'max-height':maxHeight}"
             :class="{'big-preview':bigPreviewComplete, 'img-uploaded':isPreview}" class="img-media"></div>
      </template>
      <template v-else>
        <v-img v-if="previewIsImg && mediaUrl" v-show="!loading" :aspect-ratio="imgAspectRatio"
               :class="{'big-preview':bigPreviewComplete, 'img-uploaded':isPreview}" :cover="imgCover"
               :height="height" :max-width="maxWidth" :max-height="maxHeight" :src="mediaUrl" class="img-media">
          <template v-slot:placeholder>
            <image-loader></image-loader>
          </template>
          <slot></slot>
        </v-img>
      </template>
      <vue-pdf-embed v-if="previewIsPdf && mediaUrl" :class="{'modal-all-page':bigPreviewComplete}"
                     :height="bigPreviewComplete?660:pdfHeight"
                     :page="pdfPages" :source="mediaUrl" class="pdf-preview"/>
      <video v-if="previewIsVideo && mediaUrl" :src="mediaUrl" controls></video>
    </div>
    <div v-if="showDownloadBtn" :class="{'download-preview':isPreview}" class="wrapper-download">
      <a v-show="mediaUrl" :download="downloadAttribute" :href="mediaUrl" target="_blank"
         :class="{'v-btn v-btn--variant-plain':variantPlain, 'v-btn b-inverted':!variantPlain, 'link-download':previewAsLink }">
        <v-icon v-if="icon" :icon="icon"></v-icon>
        <span>{{
            downloadBtnLabel === null ? '' : (downloadBtnLabel ? downloadBtnLabel : $t('global.download'))
          }}</span></a>
    </div>
  </div>
</template>

<style lang="less" scoped>
video {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-size: cover;
  overflow: hidden;
}

.img-media {
  height: 100%;
  .set-img();
  background-size: cover;
}

.link-download {
  .set-border-radius(0);
  background: transparent !important;
  color: @font-color-secondary !important;
  height: auto !important;
  //padding: 10px 0 1px 0;
  padding: 10px 0;
  //border-bottom:2px solid @primary-color;

  .v-icon {
    margin-right: 10px;
  }

  span {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: @dark-grey;
    text-underline-offset: 6px;
  }
}

//MOBILE
.mobile-app {
  .link-download {
    padding: 13px;
    display: block;

    i {
      display: none;
    }

    span {
      word-break: break-all;
      line-height: 25px;
      text-align: left !important;
    }
  }
}
</style>