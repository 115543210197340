<template>
  <v-footer tag="footer" :class="{'mobile-footer':mobile}">
    <v-container>
      <v-row class="footer-core">
        <v-col v-for="cl in colsLinks" v-show="!mobile" cols="3">
          <v-img v-if="cl.logo" class="logo-footer" src="@/assets/img/logo.svg">
            <template v-slot:placeholder>
              <image-loader></image-loader>
            </template>
          </v-img>
          <div class="title-section-footer">{{ cl.title }}</div>
          <v-list class="list-footer" density="compact" nav>
            <v-list-item v-for="link in cl.links" :key="link" :href="link.href"
                         :title="link.title" :to="link.to ? link.to : undefined" :value="link.value"></v-list-item>
          </v-list>
        </v-col>

        <v-expansion-panels v-show="mobile" class="mobile-exp-footer">
          <v-expansion-panel v-for="cl in colsLinks">
            <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
              <v-img v-if="cl.logo" class="logo-footer" src="@/assets/img/logo.svg">
                <template v-slot:placeholder>
                  <image-loader></image-loader>
                </template>
              </v-img>
              <div v-else class="title-section-footer">{{ cl.title }}</div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-list class="list-footer" density="compact" nav>
                <v-list-item v-for="link in cl.links" :key="link" :href="link.href"
                             :title="link.title" :to="link.to ? link.to : undefined" :value="link.value"></v-list-item>
              </v-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-col :class="{'mobile-info':mobile}" cols="3">
          <v-row class="info">
            <div>{{ $t(pageLabelPrefix + "consob_subscription") }}</div>
            <div>{{ $t(pageLabelPrefix + "consob_subscription_date") }}</div>
            <div>{{ $t(pageLabelPrefix + "consob_subscription_number") }}</div>
          </v-row>
          <v-row>
            <v-list class="list-social">
              <v-list-item v-for="socialLink in socialLinks" :key="socialLink.to" :href="socialLink.to"
                           :prepend-icon="socialLink.icon" :value="socialLink.name"
                           v-bind:title.attr="socialLink.name"></v-list-item>
            </v-list>
          </v-row>
        </v-col>
      </v-row>

      <div class="footer-warning">
        <div class="subtitle-avv">{{ $t(pageLabelPrefix + "footer_warning_lbl") }}</div>
        <div v-html="$t(pageLabelPrefix + 'footer_warning')"></div>
      </div>

      <v-row class="cop-info">
        <div>
          <v-icon icon="mdi-copyright"></v-icon>
          {{ currentYear }} {{ bildapName }} | {{address}} |
          {{ $t(pageLabelPrefix + 'vat', {vat_number: vat_number}) }}
        </div>
        <div v-if="!isProd" style="color:#00cccc;font-weight: bolder;">
          <br/>
          Environment: {{ currentMode.toUpperCase() }} - Versione: {{ version }}
        </div>
      </v-row>

    </v-container>
  </v-footer>
</template>

<script setup>
import ImageLoader from "@/components/ImageLoader.vue";
import {useI18n} from "vue-i18n";
import {useDisplay} from "vuetify";
import {useCookiebot} from "@ambitiondev/vue-cookiebot";
import {onMounted} from "vue";

const {t: $t, locale} = useI18n();
// You can provide optional settings for consent banner
const {consentBanner} = useCookiebot({
  culture: locale
});

const currentYear = new Date().getFullYear();

const isProd = import.meta.env.PROD && import.meta.env.MODE === 'prod';
const currentMode = import.meta.env.MODE;
const version = __APP_VERSION__;

const vat_number = "IT" + import.meta.env.VITE_VAT_NUMBER;
const bildapName = import.meta.env.VITE_LEGAL_NAME;
const address = import.meta.env.VITE_COPYRIGHT_ADDRESS;
const esma_url = import.meta.env.VITE_ESMA_URL;

const pageLabelPrefix = 'footer.';
const bottomNavigationLabelPrefix = 'bottom_navigation.';
const colsLinks = [{
  logo: true,
  links: [{
    title: $t(bottomNavigationLabelPrefix + 'fund_with_us'),
    to: '/all-projects',
    value: $t(bottomNavigationLabelPrefix + 'fund_with_us')
  },
    {
      title: $t(bottomNavigationLabelPrefix + 'collect_funds'),
      to: '/collect',
      value: $t(bottomNavigationLabelPrefix + 'collect_funds')
    },
    {
      title: $t(bottomNavigationLabelPrefix + 'how_it_works'),
      to: '/how-it-works',
      value: $t(bottomNavigationLabelPrefix + 'how_it_works')
    },
    {
      title: $t(bottomNavigationLabelPrefix + 'us'),
      to: '/about-us',
      value: $t(bottomNavigationLabelPrefix + 'us')
    },
    {
      title: $t(bottomNavigationLabelPrefix + 'company_info'),
      to: '/company-info',
      value: $t(bottomNavigationLabelPrefix + 'company_info')
    },
    {
      title: $t(bottomNavigationLabelPrefix + 'consob_official'),
      href: esma_url,
      value: $t(bottomNavigationLabelPrefix + 'consob_official')
    }]
},
  {
    title: $t(bottomNavigationLabelPrefix + 'legal'),
    links: [{
      title: $t(bottomNavigationLabelPrefix + 'tos'),
      to: '/tos',
      value: $t(bottomNavigationLabelPrefix + 'tos')
    }, {
      title: $t(bottomNavigationLabelPrefix + 'app_info'),
      to: '/app-info',
      value: $t(bottomNavigationLabelPrefix + 'app_info')
    },
      {
        title: $t(bottomNavigationLabelPrefix + 'fund_info'),
        to: '/fund-info',
        value: $t(bottomNavigationLabelPrefix + 'fund_info')
      },
      {
        title: $t(bottomNavigationLabelPrefix + 'conflict_policy'),
        to: '/conflict-policy',
        value: $t(bottomNavigationLabelPrefix + 'conflict_policy')
      },
      {
        title: $t(bottomNavigationLabelPrefix + 'privacy'),
        to: '/privacy-policy',
        value: $t(bottomNavigationLabelPrefix + 'privacy')
      },
      {
        title: $t(bottomNavigationLabelPrefix + 'cookie'),
        to: '/cookie-policy',
        value: $t(bottomNavigationLabelPrefix + 'cookie')
      },

      {
        title: $t(bottomNavigationLabelPrefix + 'investor_education'),
        href: 'https://www.consob.it/web/investor-education/crowdfunding',
        value: $t(bottomNavigationLabelPrefix + 'investor_education')
      }
    ]
  },
  {
    title: $t(bottomNavigationLabelPrefix + 'support'),
    links: [{
      title: $t(bottomNavigationLabelPrefix + 'contact_us'),
      href: 'mailto:' + import.meta.env.VITE_SUPPORT_EMAIL + "?subject=" + import.meta.env.VITE_SUPPORT_OBJECT,
      value: $t(bottomNavigationLabelPrefix + 'contact_us')
    },
      {
        title: $t(bottomNavigationLabelPrefix + 'work_with_us'),
        href: 'mailto:' + import.meta.env.VITE_RECRUIMENT_EMAIL + "?subject=" + import.meta.env.VITE_RECRUIMENT_OBJECT,
        value: $t(bottomNavigationLabelPrefix + 'work_with_us')
      }, {
        title: $t(bottomNavigationLabelPrefix + 'faq'),
        to: '/faq',
        value: $t(bottomNavigationLabelPrefix + 'faq')
      }, {
        title: $t(bottomNavigationLabelPrefix + 'alternate'),
        to: '/alternate',
        value: $t(bottomNavigationLabelPrefix + 'alternate')
      }, {
        title: $t(bottomNavigationLabelPrefix + 'assessment'),
        to: '/assessment',
        value: $t(bottomNavigationLabelPrefix + 'assessment')
      }, {
        title: $t(bottomNavigationLabelPrefix + 'limits_check'),
        to: '/limits-check',
        value: $t(bottomNavigationLabelPrefix + 'limits_check')
      },
      {
        title: $t(bottomNavigationLabelPrefix + 'claim'),
        to: '/claim',
        value: $t(bottomNavigationLabelPrefix + 'claim')
      }]
  }];

const socialLinks = [
  {
    icon: 'mdi-linkedin',
    name: 'linkedin',
    to: 'https://www.linkedin.com/company/bildap/'
  }, {
    icon: 'mdi-facebook',
    name: 'facebook',
    to: 'https://www.facebook.com/bildap.investment/'
  }];

const {mobile} = useDisplay();

onMounted(() => consentBanner());
</script>

<style lang="less" scoped>
footer {
  padding-inline: 0;
  padding: 0;
  padding-top: 60px;

  background: @secondary-color;
  color: @white;

  .v-container {
    max-width: 100%;
    padding: 0;
    padding-inline: 0;
  }
}

.title-section-footer {
  font-weight: 400;
  font-size: 1.8em;
  margin-bottom: 40px;
}

.logo-footer {
  width: 250px;
  position: absolute;
  top: -20px;
  left: -8px;

  & + .title-section-footer {
    margin-bottom: 83px;
  }
}

//stile generico liste
.list-footer {
  padding-left: 0;
  position: relative;
  left: -8px;
  background: transparent;
  color: @white;
}

//box con le informazioni e i social
.info {
  margin-top: 78px;
  font-weight: 300;
  font-size: 17px;
  line-height: 40px;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 2px;
    background: @primary-color;
    height: 300px;
    position: absolute;
    left: -50px;
    top: -30px;

    @media @max-1199 {
      display: none;
    }
  }
}

.list-social {
  display: flex;
  background: transparent;
  width: 100%;
  column-gap: 10px;

  @size-round: 48px;

  .v-list-item {
    .set-border-radius(@size-round);
    background: @primary-color;
    width: @size-round;
    height: @size-round;
    padding-inline: 0;
    display: flex;
    justify-content: center;

    .v-list-item__spacer {
      display: none;
    }
  }
}

@space-border: 40px;
//riga centrale
.footer-core {
  max-width: 1800px;
  margin: auto;
  padding-left: @space-border;
  padding-right: @space-border;

  .v-col {
    position: relative;
  }
}

//riga in fondo nel footer
.cop-info {
  background: @primary-color;
  padding: 40px;
  padding-bottom: 45px;
  margin: 0;

  div {
    padding-left: (@space-border + 15px);
    padding-right: (@space-border + 15px);
    max-width: 1800px;
    margin: auto;
    width: 100%;
    color: @secondary-color;
    font-weight: 500;
  }
}

.footer-warning{
  font-family: FilsonPro, Serif;
  font-size: 14px;
  max-width: 1800px;
  margin: auto;
  padding: 40px;
  line-height: 20px;
  font-weight: 300;
  text-align: justify;

  .subtitle-avv{
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 400;
  }
}


//MOBILE
//colonna con le info e i social
.mobile-info {
  flex: 1;
  max-width: 100%;
  margin-bottom: 60px;

  .v-row {
    display: block;
  }
}

//riga in fondo a tutto
.mobile-footer {
  .cop-info {
    div {
      padding: 0;
    }
  }

  .info {
    //nascondo la riga verde
    &:after {
      display: none;
    }
  }
}
</style>