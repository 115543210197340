import {computed, reactive} from 'vue';
import {defineStore} from 'pinia';
import {EndpointsFactory} from "@/utilities/endpoints";
import {WorkerResponseCode} from "@/utilities/workerUtilites";
import {useAppStore} from "@/stores/app";
import {TEXT_FIELDS} from "@/utilities/enums";

export const useTextStore = defineStore('text', () => {
	const serverData = reactive({
		contract: ''
	});

	const contract = computed(() => {
		return serverData.contract[TEXT_FIELDS.VALUE];
	});

	function reset(){
		serverData.contract = '';
	}

	function _fetchContractText(currentLang, currentToken){
		return new Promise((resolve, reject) => {
			if(serverData.contract){ //aggiorno solo se non lo ho
				return resolve();
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.GET_CONTRACT_TEXT, {
					language_code: currentLang
				}),
				token: currentToken,
				method: 'GET'
			}, WorkerResponseCode.GET_CONTRACT_TEXT, ({result}) => {
				serverData.contract = result;
				resolve();
			}, () => {
				reject();
			});
		});
	}

	async function loadTextData(currentToken, currentLang, admin = false, guest = false){
		const appStore = useAppStore();
		appStore.showSpinner();
		try{
			await Promise.all([_fetchContractText(currentLang, currentToken)]);
			appStore.hideSpinner(false, true);
		}catch (e){
			appStore.showError();
		}
	}

	return {
		contract,

		loadTextData,
		reset,
		//questo è esposto solo perché così viene salvato in locale; ma non è pensato per essere usato
		serverData
	};
}, {
	persist: true
});