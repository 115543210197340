import {createRouter, createWebHistory} from 'vue-router';
import HomeView from '../views/public/HomeView.vue';
import {useUserStore} from "@/stores/user";
import {storeToRefs} from "pinia";

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	scrollBehavior(to, from, savedPosition){
		// more info https://router.vuejs.org/guide/advanced/scroll-behavior.html
		if(to.hash){
			return {el: to.hash, behavior: 'smooth'}
		}
		// always scroll to top
		return {top: 0}
	},
	//NOTA: se aggiungi una pagina pubblica, ricordati di aggiungerne il path in pages.js così che vada in sitemap!
	routes: [
		{
			path: '/',
			name: 'home',
			alias: '/home',
			component: HomeView
		},
		{
			path: '/equity',
			name: 'equity',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/EquityView.vue')
		},
		{
			path: '/debt',
			name: 'debt',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/DebtView.vue')
		},
		{
			path: '/all-projects',
			alias: '/investi',
			name: 'allprojects',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/FundView.vue')
		},
		{
			path: '/collect',
			name: 'collect',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/CollectView.vue')
		},
		{
			path: '/how-it-works',
			name: 'howitworks',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/HowItWorksView.vue')
		},
		{
			path: '/about-us',
			name: 'aboutus',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/AboutUsView.vue')
		},
		{
			path: '/chi-siamo', //redirect del vecchio link
			redirect: {name: 'aboutus'}
		},
		{
			path: '/faq',
			name: 'faq',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/FaqView.vue')
		},
		{
			path: '/company-info',
			name: 'companyinfo',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/CompanyInfoView.vue')
		},
		{
			path: '/tos',
			name: 'tos',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/TosView.vue')
		},
		{
			path: '/privacy-policy',
			name: 'privacypolicy',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/PrivacyPolicyView.vue')
		},
		{
			path: '/cookie-policy',
			name: 'cookiepolicy',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/CookiePolicyView.vue')
		},
		{
			path: '/conflict-policy',
			name: 'conflictpolicy',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/ConflictPolicyView.vue')
		},
		{
			path: '/whistleblowing-policy',
			name: 'whistleblowingpolicy',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/WhistleblowingPolicyView.vue')
		},
		{
			path: '/claim-policy',
			name: 'claimpolicy',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/ClaimPolicyView.vue')
		},
		{
			path: '/app-info',
			name: 'appinfo',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/AppInfoView.vue')
		},
		{
			path: '/fund-info',
			name: 'fundinfo',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/FundInfoView.vue')
		},
		{
			path: '/alternate',
			name: 'alternate',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/AlternateView.vue')
		},
		{
			path: '/assessment',
			name: 'assessment',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/AssessmentView.vue')
		},
		{
			path: '/limits-check',
			name: 'limitscheck',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/LimitsCheckView.vue')
		},
		{
			path: '/claim',
			name: 'claim',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/ClaimView.vue')
		},
		{
			path: '/fraud-policy',
			name: 'fraud',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/FraudPolicyView.vue')
		},
		{
			path: '/account',
			meta: {requiresGuest: true},
			children: [{
				path: 'login',
				name: 'login',

				// route level code-splitting
				// this generates a separate chunk (About.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('../views/public/LoginView.vue')
			},
				{
					path: 'signup',
					name: 'signup',
					// route level code-splitting
					// this generates a separate chunk (About.[hash].js) for this route
					// which is lazy-loaded when the route is visited.
					component: () => import('../views/public/SignupView.vue')
				},
				{
					path: 'forgot-password',
					name: 'forgotpassword',
					// route level code-splitting
					// this generates a separate chunk (About.[hash].js) for this route
					// which is lazy-loaded when the route is visited.
					component: () => import('../views/public/ForgotPasswordView.vue')
				},
				{
					path: 'verify',
					name: 'verifymail',
					// route level code-splitting
					// this generates a separate chunk (About.[hash].js) for this route
					// which is lazy-loaded when the route is visited.
					component: () => import('../views/public/VerifyMailView.vue')
				}]
		},
		{
			path: '/project/:id',
			name: 'project',
			children: [
				{
					path: '',
					name: 'project',
					// route level code-splitting
					// this generates a separate chunk (About.[hash].js) for this route
					// which is lazy-loaded when the route is visited.
					component: () => import('../views/public/ProjectView.vue'),
					props: true
				},
				{
					path: 'fund',
					name: 'fund',
					// route level code-splitting
					// this generates a separate chunk (About.[hash].js) for this route
					// which is lazy-loaded when the route is visited.
					component: () => import('../views/public/ProjectFundView.vue'),
					meta: {requiresAuth: true}
				}
			]
		},
		{
			path: '/dashboard',
			// only authenticated users can create posts
			meta: {requiresAuth: true},
			children: [
				{
					path: 'user',
					beforeEnter: (to, from, next) => {
						const userStore = useUserStore();
						//sappiamo già è loggato dai controlli su /dashboard
						const {isAdmin} = storeToRefs(userStore);
						if(isAdmin.value){
							next('/dashboard/admin/');
						}
						else{
							next();
						}
					},
					component: () => import('../views/dashboards/user/DashboardView.vue'),
					children: [
						{
							path: 'home',
							alias: '',
							name: 'userdashboard',
							component: () => import('../views/dashboards/user/HomeView.vue')
						},
						{
							path: 'assets',
							redirect: 'assets/active',
							component: () => import('../views/dashboards/user/AssetsView.vue'),
							children: [
								{
									path: 'active',
									name: 'assets_active',
									component: () => import('../views/dashboards/user/assets/ActiveView.vue')
								},
								{
									path: 'ended',
									name: 'assets_ended',
									component: () => import('../views/dashboards/user/assets/EndedView.vue')
								}
							]
						},
						{
							path: 'transactions',
							name: 'transactions',
							component: () => import('../views/dashboards/user/TransactionsView.vue')
						},
						{
							path: 'profile',
							name: 'profile',
							component: () => import('../views/dashboards/user/ProfileView.vue')
						},
						{
							path: 'qualifications',
							name: 'qualifications',
							component: () => import('../views/dashboards/user/QualificationsView.vue')
						},
						{
							path: 'mifidtest',
							name: 'mifidtest',
							component: () => import('../views/dashboards/user/MifidTestView.vue')
						},
						{
							path: 'losstest',
							name: 'losstest',
							component: () => import('../views/dashboards/user/LossTestView.vue')
						},
						{
							path: 'investorprofile',
							name: 'investorprofile',
							component: () => import('../views/dashboards/user/InvestorProfileView.vue')
						},
						{
							path: 'password',
							name: 'password',
							component: () => import('../views/dashboards/user/PasswordView.vue')
						},
						{
							path: 'settings',
							name: 'settings',
							component: () => import('../views/dashboards/user/SettingsView.vue')
						}
					]
				},
				{
					path: 'admin',
					beforeEnter: (to, from, next) => {
						const userStore = useUserStore();
						//sappiamo già è loggato dai controlli su /dashboard
						const {isAdmin} = storeToRefs(userStore);
						if(!isAdmin.value){
							next('/');
						}
						else{
							next();
						}
					},
					component: () => import('../views/dashboards/admin/DashboardView.vue'),
					children: [
						{
							path: 'home',
							name: 'admin_dashboard',
							component: () => import('../views/dashboards/admin/HomeView.vue'),
							alias: ''
						},
						{
							path: 'users',
							children: [{
								path: 'status/:val',
								name: 'admin_users',
								props: true,
								component: () => import('../views/dashboards/admin/UsersView.vue')
							},
								{
									path: 'professional',
									name: 'admin_users_professional',
									component: () => import('../views/dashboards/admin/UsersProfessionalView.vue')
								}]
						},
						{
							path: 'user/:id',
							name: 'admin_user_detail',
							props: true,
							component: () => import('../views/dashboards/admin/UserView.vue')
						},
						{
							path: 'profile',
							name: 'admin_profile',
							component: () => import('../views/dashboards/admin/ProfileView.vue')
						},
						{
							path: 'orders',
							name: 'admin_orders',
							component: () => import('../views/dashboards/admin/OrdersView.vue')
						},
						{
							path: 'companies',
							name: 'admin_companies',
							component: () => import('../views/dashboards/admin/CompaniesView.vue')
						},
						{
							path: 'company/:id',
							name: 'admin_company',
							props: true,
							component: () => import('../views/dashboards/admin/CompanyView.vue')
						},
						{
							path: 'projects',
							redirect: 'projects/created',
							children: [{
								path: ':projects_status',
								props: true,
								name: 'admin_projects',
								component: () => import('../views/dashboards/admin/ProjectsView.vue')
							}]
						},
						{
							path: 'project/:id',
							name: 'admin_project',
							props: true,
							component: () => import('../views/dashboards/admin/ProjectView.vue')
						},
						{
							path: 'proposals',
							name: 'admin_proposals',
							component: () => import('../views/dashboards/admin/ProposalsView.vue')
						},
						{
							path: 'proposal/:id',
							name: 'admin_proposal',
							props: true,
							component: () => import('../views/dashboards/admin/ProposalView.vue')
						},
						{
							path: 'tests',
							children: [
								{
									path: 'suitability',
									name: 'admin_suitability_test',
									component: () => import('../views/dashboards/admin/tests/SuitabilityTestView.vue')
								},
								{
									path: 'profiles',
									name: 'admin_suitability_profiles',
									component: () => import('../views/dashboards/admin/tests/ProfilesView.vue')
								},
								{
									path: 'loss_simulation',
									name: 'admin_loss_simulation_test',
									component: () => import('../views/dashboards/admin/tests/LossSimulationTestView.vue')
								}
							]
						},
						{
							path: 'ticket',
							name: 'admin_ticket',
							component: () => import('../views/dashboards/admin/TicketView.vue')
						},
						{
							path: 'users_status',
							name: 'admin_users_status',
							component: () => import('../views/dashboards/admin/UsersStatusView.vue')
						},
						{
							path: 'economic_data',
							name: 'admin_economic_data',
							component: () => import('../views/dashboards/admin/EconomicDataView.vue')
						}
					]
				}
			]
		},
		// everything else will match everything and put it under `$route.params.pathMatch` (we don't use a specific 404 page, but we redirect to homepage)
		{path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('../views/public/404View.vue')}
	]
});

router.beforeEach((to, from) => {
	const userStore = useUserStore();
	// instead of having to check every route record with
	// to.matched.some(record => record.meta.requiresAuth)
	if(to.meta.requiresAuth && (!userStore.isLoggedIn || !userStore.isVerified)){
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		return {
			path: '/account/login',
			// save the location we were at to come back later
			query: {redirect: to.fullPath}
		}
	}
	if(to.meta.requiresGuest && userStore.isLoggedIn){
		// this route requires not auth, check if logged in
		// if yes, redirect to home page.
		return {
			path: '/',
		}
	}
});

router.afterEach((to, from) => {
	if(to.name === 'project' || from.name === 'fund'){ //reset current fund data
		const userStore = useUserStore();
		userStore.unsetCurrentFund();
	}
});

export default router;
