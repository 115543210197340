export const WorkerResponse = {
	ERROR: 'error',
	SUCCESS: 'success'
};

export const DecodeResponseType = {
	NOTHING: 0,
	TEXT: 1,
	JSON: 2,
	BLOB: 3
};

export const WorkerResponseCode = {
	LOGIN: 'login',
	MFA_VERIFY: 'mfa_verify',
	ASK_CODE: 'ask_code',
	LOGOUT: 'logout',
	MFA_TEST: 'mfa_test',
	MFA_TEST_CODE: 'mfa_test_code',

	GET_COUNTRIES_LABELS: 'get_countries_labels',
	GET_COUNTRIES: 'get_countries',
	GET_GENDERS_LABELS: 'get_genders_labels',
	GET_GENDERS: 'get_genders',
	GET_CITIES: 'get_cities',
	GET_STATES: 'get_states',
	GET_DOCUMENT_TYPES_LABELS: 'get_document_types_labels',
	GET_DOCUMENT_TYPES: 'get_document_types',
	GET_BUSINESS_AREAS: 'get_business_areas',
	GET_BUSINESS_AREAS_LABELS: 'get_business_areas_labels',
	GET_INVESTOR_TYPES: 'get_investor_types',
	GET_INVESTOR_TYPES_LABELS: 'get_investor_types_labels',
	GET_SPV_STATUSES: 'get_spv_statuses',
	GET_SPV_STATUSES_LABELS: 'get_spv_statuses_labels',
	GET_SPV_FUNDS_SOURCES: 'get_spv_funds_sources',
	GET_SPV_FUNDS_SOURCES_LABELS: 'get_spv_funds_sources_labels',
	GET_PROFILE_STATUSES: 'get_profile_statuses',
	GET_PROFILE_STATUSES_LABELS: 'get_profile_statuses_labels',
	GET_PROJECT_RATES: 'get_project_rates',
	GET_PROJECT_RATES_LABELS: 'get_project_rates_labels',
	GET_PROJECT_STATUSES: 'get_project_statuses',
	GET_PROJECT_STATUSES_LABELS: 'get_project_statuses_labels',
	GET_PROJECT_RISK_PROFILES: 'get_project_risk_profiles',
	GET_PROJECT_RISK_PROFILES_LABELS: 'get_project_risk_profiles_labels',
	GET_PROJECT_UPDATE_STATUSES: 'get_project_update_statuses',
	GET_PROJECT_UPDATE_STATUSES_LABELS: 'get_project_update_statuses_labels',
	GET_PROJECT_ORDER_STATUSES: 'get_project_order_statuses',
	GET_PROJECT_ORDER_STATUSES_LABELS: 'get_project_order_statuses_labels',
	GET_ORDER_ERRORS: 'get_order_errors',
	GET_ORDER_ERRORS_LABELS: 'get_order_errors_labels',
	GET_PROPOSAL_STATUSES: 'get_proposal_statuses',
	GET_PROPOSAL_STATUSES_LABELS: 'get_proposal_statuses_labels',
	GET_PROPERTY_TYPES: 'get_property_types',
	GET_PROPERTY_TYPES_LABELS: 'get_property_types_labels',
	GET_DURATIONS: 'get_durations',
	GET_DURATIONS_LABELS: 'get_durations_labels',
	GET_PURPOSES: 'get_purposes',
	GET_PURPOSES_LABELS: 'get_purposes_labels',

	SIGNUP: 'signup',
	FORGOT_PWD: 'forgot_password',
	MFA_DELETE: 'mfa_delete',
	MFA_CHANGE: 'mfa_change',
	GET_VERIFY_LINK: 'get_verify_link',
	VERIFY: 'verify',
	VALIDATE_USER: 'validate_user',

	UPLOAD_DOCUMENT: 'upload_document',
	GET_MEDIA: 'get_media',

	GET_COMPANIES: 'get_companies',
	GET_COMPANIES_READY: 'get_companies_ready',
	GET_COMPANY: 'get_company',
	SAVE_COMPANY: 'save_company',
	DELETE_COMPANY: 'delete_company',
	GET_COMPANY_TEAM: 'get_spv_team',
	CREATE_COMPANY_TEAM: 'create_spv_team',
	DELETE_COMPANY_TEAM: 'delete_spv_team',
	EDIT_COMPANY_TEAM: 'delete_spv_team',
	GET_COMPANY_DOCS: 'get_company_docs',
	DELETE_COMPANY_DOC: 'delete_company_doc',
	UPLOAD_COMPANY_DOC: 'upload_company_doc',
	CREATE_COMPANY_FUND: 'create_spv_fund',
	GET_COMPANY_FUNDS: 'get_spv_funds',
	CHANGE_COMPANY_STATUS: 'change_company_status',

	UPDATE_USER_PERSONAL_DATA: 'update_user_personal_data',
	UPDATE_USER_PERSONAL_DATA_BUSINESS: 'update_user_personal_data_business',
	UPDATE_USER_RESIDENCE: 'update_user_residence',
	UPDATE_USER_DOCUMENT: 'update_user_document',
	CHANGE_PWD: 'change_pwd',
	REQUEST_CHANGE_INVESTOR_TYPE: 'request_change_investor_type',
	REPLY_CHANGE_INVESTOR_TYPE: 'reply_change_investor_type',
	USER_ACCEPT_CONTRACT: 'user_accept_contract',
	SET_USER_RUBRICATION: 'set_user_rubrication',
	UPDATE_USER: 'update_user',

	GET_INVESTOR_REQUESTS: 'get_investor_requests',
	GET_ALL_USERS: 'get_all_users',
	GET_USER: 'get_user',
	DELETE_USER: 'delete_user',
	EXPORT_USERS: 'export_users',

	CREATE_PROJECT_DEBT: 'create_project_debt',
	CREATE_PROJECT_EQUITY: 'create_project_equity',
	UPDATE_PROJECT_DEBT: 'update_project_debt',
	UPDATE_PROJECT_EQUITY: 'update_project_equity',
	GET_PROJECT: 'get_project',
	GET_ALL_PROJECTS: 'get_all_projects',
	DELETE_PROJECT: 'delete_project',
	GET_EQUITY_PROJECT_QUOTAS: 'get_equity_project_quotas',
	GET_DEBT_PROJECT_QUOTAS: 'get_debt_project_quotas',
	CREATE_EQUITY_PROJECT_QUOTA: 'create_equity_project_quota',
	CREATE_DEBT_PROJECT_QUOTA: 'create_debt_project_quota',
	UPDATE_EQUITY_PROJECT_QUOTA: 'update_equity_project_quota',
	UPDATE_DEBT_PROJECT_QUOTA: 'update_debt_project_quota',
	DELETE_EQUITY_PROJECT_QUOTA: 'delete_equity_project_quota',
	DELETE_DEBT_PROJECT_QUOTA: 'delete_debt_project_quota',
	UPLOAD_PROJECT_DOC: 'upload_project_doc',
	GET_PROJECT_DOCS: 'get_project_docs',
	DELETE_PROJECT_DOC: 'delete_project_doc',
	GET_PROJECT_UPDATES: 'get_project_updates',
	CREATE_PROJECT_UPDATE: 'create_project_update',
	DELETE_PROJECT_UPDATE: 'delete_project_update',
	UPDATE_PROJECT_UPDATE: 'update_project_update',
	GET_PROJECT_ORDERS: 'get_project_orders',
	PREPARE_ORDER: 'prepare_order',
	CONFIRM_ORDER: 'confirm_order',
	EXPORT_PROJECT_ORDERS: 'export_project_orders',
	GET_ALL_ORDERS: 'get_all_orders',
	EXPORT_ALL_ORDERS: 'export_all_orders',
	GET_USER_ORDERS: 'get_user_orders',
	GET_HOMEPAGE_PROJECTS: 'get_homepage_projects',
	SAVE_PROJECT_LIQUIDATION_DATA: 'save_project_liquidation_data',
	GET_PROJECTS_WITH_STATUS: 'get_projects_with_status',
	GET_PROJECT_REPORT: 'get_project_report',
	CHECK_USER_INVESTED_IN_PROJECT: 'check_user_invest_in_project',
	GET_PROJECT_REPORT_CSV: 'get_project_report_csv',
	CHANGE_ORDER_STATUS: 'change_order_status',

	GET_CONTRACT_TEXT: 'get_contract_text',
	GET_TOS: 'get_tos',

	GET_SURVEY: 'get_survey',
	SEND_SURVEY_ANSWERS: 'send_survey_answers',
	GET_SURVEY_RESULTS: 'get_survey_results',
	GET_USER_SURVEY_RESULTS: 'get_user_survey_results',

	GET_SIMULATIONS: 'get_simulations',
	SEND_SIMULATION: 'send_simulation',
	GET_USER_SIMULATIONS: 'get_user_simulations',

	CREATE_PROPOSAL: 'create_proposal',
	GET_ALL_PROPOSALS: 'get_all_proposals',
	UPDATE_PROPOSAL: 'update_proposal',
	GET_PROPOSAL: 'get_proposal',

	DASHBOARD_USER_GET_ORDERS: 'dashboard_user_get_orders',
	DASHBOARD_ADMIN: 'dashboard_admin',
	DASHBOARD_USER_GET_UPDATES: 'dashboard_user_get_updates',
	DASHBOARD_USER_PERFORMANCES: 'dashboard_user_get_performances',
	DASHBOARD_USER_ASSETS: 'dashboard_user_get_assets'
};