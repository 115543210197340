import {WorkerResponse} from "@/utilities/workerUtilites";
import {useAppStore} from "@/stores/app";
import {useUserStore} from "@/stores/user";
import {ORDER_FIELDS} from "@/utilities/enums";
import {storeToRefs} from "pinia";

const ENDPOINTS = {
	V1: 'v1',
	USERS: 'users',
	AUTH: 'auth',
	CODE: 'code',
	RESET: 'reset',
	SIGNIN: 'signin',
	SPVS: 'spvs', //special purpose vehicle -- società veicolo
	SPV: 'spv',
	MFA_NUMBER: 'mfa_number',
	LABELS: 'labels',
	COUNTRIES: 'countries',
	DOMS: 'doms',
	STATES: 'provinces',
	GENDERS: 'genders',
	CITIES: 'cities',
	DOCUMENT_TYPES: 'documenttypes',
	NEWSLETTER: 'newsletter',
	PASSWORD: 'password',
	DOCS: 'docs',
	VERIFY: 'verify',
	VERIFYLINK: 'verifylink',
	RESIDENCE: 'residence',
	ID_CARD: 'idcard',
	MEDIA: 'media',
	BUSINESS_AREAS: 'businessareas',
	INVESTOR_TYPES: 'investortypes',
	INVESTOR: 'investor',
	TEAM: 'team',
	SPV_STATUSES: 'spvstatuses',
	PROFILE_STATUSES: 'profilestatuses',
	FUNDS: 'funds',
	STATUS: 'status',
	OFFERS: 'offers',
	EQUITY: 'equity',
	DEBT: 'debt',
	RATES: 'rates',
	EXPORT: 'export',
	STATUSES: 'statuses',
	PRICES: 'prices',
	READY: 'ready',
	RISKS: 'risks',
	FILES: 'files',
	UPDATES: 'updates',
	PRODUCTS: 'products',
	PRODUCT: 'product',
	CONTRACT: 'contract',
	TEXTS: 'texts',
	SURVEYS: 'surveys',
	RESPONSES: 'responses',
	SIMULATIONS: 'simulations',
	RUBRICATION: 'rubrication',
	ORDERS: 'orders',
	VALIDATION: 'validation',
	ERRORS: 'errors',
	CONFIRM: 'confirm',
	PROFILE: 'profile',
	BUSINESS: 'business',
	HOME: 'home',
	PROPOSALS: 'proposals',
	LIQUIDATE: 'liquidate',
	DASHBOARD: 'dash',
	USER: 'user',
	ADMIN: 'admin',
	PERFORMANCES: 'performances',
	TESTCODE: 'testcode',
	REPORT: 'report',
	ASSETS: 'assets',
	CHECK: 'check',
	CSV: 'csv',
	PROPERTY_TYPES: 'propertytypes',
	DURATIONS: 'durations',
	PROPERTIES: 'properties',
	PURPOSES: 'purposes',
	TOS: 'tos'
};

const QS_PARAMS = {
	LABEL_PREFIX: 'prefix=',
	EMAIL: 'email=',
	MFA_NUMBER: 'mfaNumber=',
};

let worker;
const WorkerMessageObserver = {};

export class EndpointsFactory {
	static ENDPOINTS = {
		USERS: 'users_endpoint',
		GET_ALL_USERS: 'get_all_users_endpoint',
		GET_USER: 'get_user_endpoint',
		SIGNUP: 'signup_endpoint',
		LOGIN: 'login_endpoint',
		RESET_PWD: 'reset_endpoint',
		MFA: 'mfa_endpoint',
		MFA_RESEND: 'mfa_resend_enpoint',
		LOGOUT: 'logout_endpoint',
		CREATE_COMPANY: 'spv_create_endpoint',
		UPDATE_COMPANY: 'spv_update_endpoint',
		GET_COMPANY: 'get_spv_endpoint',
		GET_COMPANIES: 'get_spvs_endpoint',
		GET_COMPANIES_READY: 'get_spvs_ready_endpoint',
		SET_MFA: 'set_mfa_endpoint',
		DELETE_MFA: 'delete_mfa_endpoint',
		GET_LABELS: 'get_labels_endpoint',
		GET_COUNTRIES: 'get_countries_endpoint',
		GET_STATES: 'get_states_endpoint',
		GET_GENDERS: 'get_genders_endpoint',
		GET_CITIES: 'get_cities_endpoint',
		GET_DOCUMENT_TYPES: 'get_documenttypes_endpoint',
		GET_BUSINESS_AREAS: 'get_businessareas_endpoint',
		GET_INVESTOR_TYPES: 'get_investortypes_endpoint',
		GET_SPV_STATUSES: 'get_spvstatuses_endpoint',
		GET_SPV_FUNDS_SOURCES: 'get_spv_funds_sources_endpoint',
		GET_PROFILE_STATUSES: 'get_profilestatuses_endpoint',
		GET_PROJECT_RATES: 'get_project_rates_endpoint',
		GET_PROJECT_STATUSES: 'get_project_statuses_endpoint',
		GET_PROJECT_RISK_PROFILES: 'get_project_risk_profiles',
		GET_PROJECT_UPDATE_STATUSES: 'get_project_update_statuses_endpoint',
		GET_PROJECT_ORDER_STATUSES: 'get_project_order_statuses_endpoint',
		SET_NEWSLETTER: 'set_newsletter_endpoint',
		CHANGE_PWD: 'change_pwd_endpoint',
		GET_VERIFY_LINK: 'get_verify_link_endpoint',
		VERIFY_LINK: 'verify_link_endpoint',
		SET_USER_RESIDENCE: 'set_user_residence_endpoint',
		SET_USER_DOCUMENT: 'set_user_document_endpoint',
		USER_ACCEPT_CONTRACT: 'user_accept_contract_endpoint',
		UPLOAD_MEDIA: 'upload_media_endpoint',
		GET_MEDIA: 'get_media_endpoint',
		REQUEST_NEW_INVESTOR_TYPE: 'request_new_investor_type_endpoint',
		GET_COMPANY_TEAM: 'get_company_team',
		CREATE_COMPANY_TEAM: 'create_company_team',
		DELETE_COMPANY_TEAM: 'delete_company_team',
		EDIT_COMPANY_TEAM: 'edit_company_team',
		GET_COMPANY_DOCS: 'get_company_docs',
		DELETE_COMPANY_DOC: 'delete_company_doc',
		UPLOAD_SPV_DOC: 'upload_spv_doc_endpoint',
		GET_INVESTOR_REQUESTS: 'get_investor_requests_endpoint',
		CREATE_SPV_FUND: 'create_spv_fund_endpoint',
		GET_SPV_FUNDS: 'get_spv_funds',
		REPLY_UPDATE_INVESTOR_REQUEST: 'reply_update_investor_request_endpoint',
		DELETE_COMPANY: 'delete_company_endpoint',
		CHANGE_COMPANY_STATUS: 'change_company_status_endpoint',
		DELETE_USER: 'delete_user_endpoint',
		CREATE_PROJECT_DEBT: 'create_project_debt_endpoint',
		CREATE_PROJECT_EQUITY: 'create_project_equity_endpoint',
		UPDATE_PROJECT_DEBT: 'update_project_debt_endpoint',
		UPDATE_PROJECT_EQUITY: 'update_project_equity_endpoint',
		GET_PROJECT: 'get_project_endpoint',
		EXPORT_USERS: 'export_users_endpoint',
		GET_ALL_PROJECTS: 'get_all_projects_endpoint',
		DELETE_PROJECT: 'delete_project_endpoint',
		GET_EQUITY_PROJECT_QUOTAS: 'get_equity_project_quotas_endpoint',
		GET_DEBT_PROJECT_QUOTAS: 'get_debt_project_quotas_endpoint',
		CREATE_EQUITY_PROJECT_QUOTA: 'create_equity_project_quota_endpoint',
		CREATE_DEBT_PROJECT_QUOTA: 'create_debt_project_quota_endpoint',
		UPDATE_EQUITY_PROJECT_QUOTA: 'update_equity_project_quota_endpoint',
		UPDATE_DEBT_PROJECT_QUOTA: 'update_debt_project_quota_endpoint',
		DELETE_EQUITY_PROJECT_QUOTA: 'delete_equity_project_quota_endpoint',
		DELETE_DEBT_PROJECT_QUOTA: 'delete_debt_project_quota_endpoint',
		UPLOAD_PROJECT_DOC: 'upload_project_doc_endpoint',
		GET_PROJECT_DOCS: 'get_project_docs_endpoint',
		DELETE_PROJECT_DOC: 'delete_project_doc_endpoint',
		GET_PROJECT_UPDATES: 'get_project_updates_endpoint',
		CREATE_PROJECT_UPDATE: 'create_project_update_endpoint',
		DELETE_PROJECT_UPDATE: 'delete_project_update_endpoint',
		UPDATE_PROJECT_UPDATE: 'update_project_update_endpoint',
		GET_CONTRACT_TEXT: 'get_contract_text_endpoint',
		GET_SURVEY: 'get_survey_endpoint',
		SEND_SURVEY_ANSWERS: 'send_survey_answers_endpoint',
		GET_SURVEY_RESULTS: 'get_survey_results_endpoint',
		GET_USER_SURVEY_RESULTS: 'get_user_survey_results_endpoint',
		GET_SIMULATIONS: 'get_simulations_endpoint',
		GET_USER_SIMULATIONS: 'get_user_simulations_endpoint',
		SEND_SIMULATION: 'send_simulation_endpoint',
		SET_USER_RUBRICATION: 'set_user_rubrication_endpoint',
		GET_PROJECT_ORDERS: 'get_project_orders_endpoint',
		VALIDATE_USER: 'validate_user_endpoint',
		PREPARE_ORDER: 'prepare_order_endpoint',
		GET_ORDER_ERRORS: 'get_order_errors_endpoint',
		CONFIRM_ORDER: 'confirm_order_endpoint',
		EXPORT_PROJECT_ORDERS: 'export_project_orders_endpoint',
		GET_ALL_ORDERS: 'get_all_orders_endpoint',
		EXPORT_ALL_ORDERS: 'export_all_orders_endpoint',
		GET_USER_ORDERS: 'get_user_orders_endpoint',
		UPDATE_USER: 'update_user_endpoint',
		UPDATE_USER_PERSONAL_DATA_BUSINESS: 'update_user_personal_data_business_endpoint',
		GET_HOMEPAGE_PROJECTS: 'get_homepage_offers_endpoint',
		POST_PROPOSAL: 'post_proposal_endpoint',
		GET_ALL_PROPOSALS: 'get_all_proposals_endpoint',
		GET_PROPOSAL_STATUSES: 'get_proposal_statuses_endpoint',
		UPDATE_PROPOSAL: 'update_proposal_endpoint',
		GET_PROPOSAL: 'get_proposal_endpoint',
		SAVE_PROJECT_LIQUIDATION_DATA: 'save_project_liquidation_data_endpoint',
		GET_USER_ORDERS_DASHBOARD: 'get_user_orders_dashboard_endpoint',
		GET_PROJECTS_WITH_STATUS: 'get_projects_with_status_endpoint',
		DASHBOARD_ADMIN: 'get_dashboard_admin_endpoint',
		DASHBOARD_USER_UPDATES: 'get_dashboard_user_updates_endpoint',
		DASHBOARD_USER_PERFORMANCES: 'get_dashboard_user_performances_endpoint',
		DASHBOARD_USER_ASSETS: 'get_user_assets_endpoint',
		MFA_TEST: 'mfa_test_endpoint',
		MFA_TEST_CODE: 'mfa_test_code_endpoint',
		GET_PROJECT_REPORT: 'get_project_report_endpoint',
		CHECK_USER_INVESTED_IN_PROJECT: 'check_user_invest_in_project_endpoint',
		GET_PROJECT_REPORT_CSV: 'get_project_report_csv_endpoint',
		CHANGE_ORDER_STATUS: 'change_order_status_endpoint',
		GET_PROPERTY_TYPES: 'get_property_types_endpoint',
		GET_DURATIONS: 'get_durations_endpoint',
		GET_PURPOSES: 'get_purposes_endpoint',
		GET_TOS: 'get_tos_endpoint'
	};

	static #API_endpoint = import.meta.env.VITE_API_ENDPOINT;

	static #users_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.USERS;
	static #spvs_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.SPVS;
	static #labels_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.LABELS;
	static #doms_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.DOMS;
	static #media_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.MEDIA;
	static #offers_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.OFFERS;
	static #texts_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.TEXTS;
	static #surveys_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.SURVEYS;
	static #simulations_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.SIMULATIONS;
	static #proposals_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.PROPOSALS;
	static #dash_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.DASHBOARD;

	static initWorker(newWorker){
		worker = newWorker;
		worker.addEventListener('message', handleWorkerMessage);
	}

	//answerCode identifica la risposta alla chiamata api. Così l'observer sa cosa aspettare e non risponde a una chiamata a caso.
	static execApiViaWorker({
		                        url,
		                        token,
		                        contentType,
		                        method,
		                        body,
		                        formData,
		                        decodeResponseType
	                        }, answerCode, successCallback, failCallback){
		if(!worker){
			throw 'WebWorker not initialised';
		}
		if(answerCode){
			if(!WorkerMessageObserver[answerCode]){
				WorkerMessageObserver[answerCode] = [];
			}
			WorkerMessageObserver[answerCode].push({
				onSuccess: successCallback,
				onFail: failCallback
			});
		}
		worker.postMessage({
			url: url,
			token: token,
			contentType: contentType,
			method: method,
			responseCode: answerCode,
			body: body,
			formData: formData,
			decodeResponseType: decodeResponseType
		});
	}

	static fetchGlobalHeaders(token = null, contentType = 'application/json'){
		const headers = new Headers();
		if(contentType){
			headers.append('Content-Type', contentType);
		}
		headers.append('Accept', 'application/json');
		if(token){
			headers.append('Authorization', `Bearer ${token}`)
		}
		return headers;
	}

	static getEndpoint(endpoint_name, {
		user_id,
		company_id,
		language_code,
		labels_prefix,
		spv_document_type,
		media_id,
		user_email,
		team_id,
		spv_doc_id,
		spv_status_id,
		project_id,
		query_params,
		quota_id,
		project_doc_type,
		project_doc_id,
		project_update_id,
		survey_id,
		proposal_id,
		status_id,
		phone_number,
		order_id
	} = {}) {
		let url;
		switch (endpoint_name){
			case this.ENDPOINTS.USERS:
			case this.ENDPOINTS.SIGNUP:
				return this.#API_endpoint + this.#users_endpoint;
			case this.ENDPOINTS.GET_ALL_USERS:
				url = this.#API_endpoint + this.#users_endpoint;
				if(query_params){
					url += "?" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.EXPORT_USERS:
				let urlExport = this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.EXPORT;
				if(query_params){
					urlExport += "?" + new URLSearchParams(query_params);
				}
				return urlExport;
			case this.ENDPOINTS.LOGIN:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.AUTH;
			case this.ENDPOINTS.RESET_PWD:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.RESET;
			case this.ENDPOINTS.MFA_RESEND:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.CODE;
			case this.ENDPOINTS.MFA:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.CODE;
			case this.ENDPOINTS.LOGOUT:
				if(!user_id){
					throw 'Missing query_user_id param';
				}
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.SIGNIN + "/" + user_id;
			case this.ENDPOINTS.GET_VERIFY_LINK:
				if(!user_email){
					throw 'Missing user_email param';
				}
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.VERIFY + "?" + QS_PARAMS.EMAIL + encodeURIComponent(user_email);
			case this.ENDPOINTS.VERIFY_LINK:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.VERIFY;
			case this.ENDPOINTS.SET_USER_RESIDENCE:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.RESIDENCE;
			case this.ENDPOINTS.SET_USER_DOCUMENT:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.ID_CARD;
			case this.ENDPOINTS.GET_INVESTOR_REQUESTS:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.INVESTOR;
			case this.ENDPOINTS.SET_MFA:
			case this.ENDPOINTS.DELETE_MFA:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.MFA_NUMBER;
			case this.ENDPOINTS.SET_NEWSLETTER:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.NEWSLETTER;
			case this.ENDPOINTS.SET_USER_RUBRICATION:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.RUBRICATION;
			case this.ENDPOINTS.CHANGE_PWD:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.PASSWORD;
			case this.ENDPOINTS.REQUEST_NEW_INVESTOR_TYPE:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.INVESTOR;
			case this.ENDPOINTS.REPLY_UPDATE_INVESTOR_REQUEST:
				if(!user_id){
					throw 'Missing query_user_id param';
				}
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.INVESTOR + "/" + user_id;
			case this.ENDPOINTS.GET_USER:
			case this.ENDPOINTS.DELETE_USER:
				if(!user_id){
					throw 'Missing query_user_id param';
				}
				return this.#API_endpoint + this.#users_endpoint + "/" + user_id;
			case this.ENDPOINTS.USER_ACCEPT_CONTRACT:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.CONTRACT;
			case this.ENDPOINTS.VALIDATE_USER:
				if(!user_id){
					throw 'Missing query_user_id param';
				}
				return this.#API_endpoint + this.#users_endpoint + "/" + user_id + "/" + ENDPOINTS.VALIDATION;
			case this.ENDPOINTS.UPDATE_USER:
				if(!user_id){
					throw 'Missing query_user_id param';
				}
				return this.#API_endpoint + this.#users_endpoint + "/" + user_id + "/" + ENDPOINTS.PROFILE;
			case this.ENDPOINTS.UPDATE_USER_PERSONAL_DATA_BUSINESS:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.BUSINESS;
			case this.ENDPOINTS.MFA_TEST:
				if(!phone_number){
					throw 'Missing phone_number param';
				}
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.TESTCODE + "?" + QS_PARAMS.MFA_NUMBER + encodeURIComponent(phone_number);
			case this.ENDPOINTS.MFA_TEST_CODE:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.TESTCODE;

			case this.ENDPOINTS.CREATE_COMPANY:
				return this.#API_endpoint + this.#spvs_endpoint;
			case this.ENDPOINTS.UPDATE_COMPANY:
			case this.ENDPOINTS.DELETE_COMPANY:
			case this.ENDPOINTS.GET_COMPANY:
				if(!company_id){
					throw 'Missing company_id param';
				}
				return this.#API_endpoint + this.#spvs_endpoint + "/" + company_id;
			case this.ENDPOINTS.GET_COMPANIES:
				return this.#API_endpoint + this.#spvs_endpoint;
			case this.ENDPOINTS.GET_COMPANIES_READY:
				return this.#API_endpoint + this.#spvs_endpoint + "/" + ENDPOINTS.READY;
			case this.ENDPOINTS.UPLOAD_SPV_DOC:
				if(!company_id){
					throw 'Missing company_id param';
				}
				if (!spv_document_type) {
					throw 'Missing spv_document_type param';
				}
				return this.#API_endpoint + this.#spvs_endpoint + "/" + company_id + "/" + ENDPOINTS.DOCS + "/" + spv_document_type;
			case this.ENDPOINTS.GET_COMPANY_DOCS:
				if(!company_id){
					throw 'Missing company_id param';
				}
				if(!spv_document_type){
					throw 'Missing docs_type param';
				}
				return this.#API_endpoint + this.#spvs_endpoint + "/" + company_id + "/" + ENDPOINTS.DOCS + "/" + spv_document_type;
			case this.ENDPOINTS.DELETE_COMPANY_DOC:
				if(!company_id){
					throw 'Missing company_id param';
				}
				if(!spv_doc_id){
					throw 'Missing spv_doc_id param';
				}
				return this.#API_endpoint + this.#spvs_endpoint + "/" + company_id + "/" + ENDPOINTS.DOCS + "/" + spv_doc_id;
			case this.ENDPOINTS.CREATE_COMPANY_TEAM:
			case this.ENDPOINTS.GET_COMPANY_TEAM:
				if(!company_id){
					throw 'Missing company_id param';
				}
				return this.#API_endpoint + this.#spvs_endpoint + "/" + company_id + "/" + ENDPOINTS.TEAM;
			case this.ENDPOINTS.EDIT_COMPANY_TEAM:
			case this.ENDPOINTS.DELETE_COMPANY_TEAM:
				if(!company_id){
					throw 'Missing company_id param';
				}
				if(!team_id){
					throw 'Missing team_id param';
				}
				return this.#API_endpoint + this.#spvs_endpoint + "/" + company_id + "/" + ENDPOINTS.TEAM + "/" + team_id;
			case this.ENDPOINTS.CREATE_SPV_FUND:
			case this.ENDPOINTS.GET_SPV_FUNDS:
				if(!company_id){
					throw 'Missing company_id param';
				}
				return this.#API_endpoint + this.#spvs_endpoint + "/" + company_id + "/" + ENDPOINTS.FUNDS;
			case this.ENDPOINTS.CHANGE_COMPANY_STATUS:
				if(!company_id){
					throw 'Missing company_id param';
				}
				if(!spv_status_id){
					throw 'Missing spv_status_id param';
				}
				return this.#API_endpoint + this.#spvs_endpoint + "/" + company_id + "/" + ENDPOINTS.STATUS + "/" + spv_status_id;

			case this.ENDPOINTS.GET_LABELS:
				if(!language_code){
					throw 'Missing language_code param';
				}
				if(!labels_prefix){
					throw 'Missing labels_prefix param';
				}
				return this.#API_endpoint + this.#labels_endpoint + "/" + language_code + "?" + QS_PARAMS.LABEL_PREFIX + labels_prefix;

			case this.ENDPOINTS.GET_COUNTRIES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.COUNTRIES;
			case this.ENDPOINTS.GET_STATES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.STATES;
			case this.ENDPOINTS.GET_GENDERS:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.GENDERS;
			case this.ENDPOINTS.GET_CITIES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.CITIES;
			case this.ENDPOINTS.GET_DOCUMENT_TYPES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.DOCUMENT_TYPES;
			case this.ENDPOINTS.GET_BUSINESS_AREAS:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.BUSINESS_AREAS;
			case this.ENDPOINTS.GET_INVESTOR_TYPES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.INVESTOR_TYPES;
			case this.ENDPOINTS.GET_SPV_STATUSES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.SPV_STATUSES;
			case this.ENDPOINTS.GET_SPV_FUNDS_SOURCES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.SPV + "/" + ENDPOINTS.FUNDS;
			case this.ENDPOINTS.GET_PROFILE_STATUSES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.PROFILE_STATUSES;
			case this.ENDPOINTS.GET_PROJECT_RATES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.OFFERS + "/" + ENDPOINTS.RATES;
			case this.ENDPOINTS.GET_PROJECT_STATUSES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.OFFERS + "/" + ENDPOINTS.STATUSES;
			case this.ENDPOINTS.GET_PROJECT_RISK_PROFILES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.OFFERS + "/" + ENDPOINTS.RISKS;
			case this.ENDPOINTS.GET_PROJECT_UPDATE_STATUSES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.OFFERS + "/" + ENDPOINTS.UPDATES + "/" + ENDPOINTS.STATUSES;
			case this.ENDPOINTS.GET_PROJECT_ORDER_STATUSES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.OFFERS + "/" + ENDPOINTS.ORDERS + "/" + ENDPOINTS.STATUSES;
			case this.ENDPOINTS.GET_ORDER_ERRORS:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.OFFERS + "/" + ENDPOINTS.ORDERS + "/" + ENDPOINTS.ERRORS;
			case this.ENDPOINTS.GET_PROPOSAL_STATUSES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.PROPOSALS + "/" + ENDPOINTS.STATUSES;
			case this.ENDPOINTS.GET_PROPERTY_TYPES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.PROPERTY_TYPES;
			case this.ENDPOINTS.GET_DURATIONS:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.OFFERS + "/" + ENDPOINTS.DURATIONS;
			case this.ENDPOINTS.GET_PURPOSES:
				return this.#API_endpoint + this.#doms_endpoint + "/" + ENDPOINTS.PROPERTIES + "/" + ENDPOINTS.PURPOSES;

			case this.ENDPOINTS.UPLOAD_MEDIA:
				return this.#API_endpoint + this.#media_endpoint + (media_id ? "/" + media_id : '');
			case this.ENDPOINTS.GET_MEDIA:
				if(!media_id){
					throw 'Missing media_id param';
				}
				return this.#API_endpoint + this.#media_endpoint + "/" + media_id;

			case this.ENDPOINTS.CREATE_PROJECT_DEBT:
				return this.#API_endpoint + this.#offers_endpoint + "/" + ENDPOINTS.DEBT;
			case this.ENDPOINTS.CREATE_PROJECT_EQUITY:
				return this.#API_endpoint + this.#offers_endpoint + "/" + ENDPOINTS.EQUITY;
			case this.ENDPOINTS.UPDATE_PROJECT_DEBT:
				if(!project_id){
					throw 'Missing project_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + ENDPOINTS.DEBT + "/" + project_id;
			case this.ENDPOINTS.UPDATE_PROJECT_EQUITY:
				if(!project_id){
					throw 'Missing project_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + ENDPOINTS.EQUITY + "/" + project_id;
			case this.ENDPOINTS.DELETE_PROJECT:
			case this.ENDPOINTS.GET_PROJECT:
				if(!project_id){
					throw 'Missing project_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + project_id;
			case this.ENDPOINTS.GET_ALL_PROJECTS:
				return this.#API_endpoint + this.#offers_endpoint;
			case this.ENDPOINTS.CREATE_EQUITY_PROJECT_QUOTA:
			case this.ENDPOINTS.GET_EQUITY_PROJECT_QUOTAS:
				if(!project_id){
					throw 'Missing project_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + ENDPOINTS.EQUITY + "/" + project_id + "/" + ENDPOINTS.PRICES;
			case this.ENDPOINTS.GET_DEBT_PROJECT_QUOTAS:
				if(!project_id){
					throw 'Missing project_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + ENDPOINTS.DEBT + "/" + project_id + "/" + ENDPOINTS.PRODUCTS;
			case this.ENDPOINTS.CREATE_DEBT_PROJECT_QUOTA:
				if(!project_id){
					throw 'Missing project_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + ENDPOINTS.DEBT + "/" + project_id + "/" + ENDPOINTS.PRODUCT;
			case this.ENDPOINTS.UPDATE_DEBT_PROJECT_QUOTA:
			case this.ENDPOINTS.DELETE_DEBT_PROJECT_QUOTA:
				if(!project_id){
					throw 'Missing project_id param';
				}
				if(!quota_id){
					throw 'Missing quota_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + ENDPOINTS.DEBT + "/" + project_id + "/" + ENDPOINTS.PRODUCT + "/" + quota_id;
			case this.ENDPOINTS.UPDATE_EQUITY_PROJECT_QUOTA:
			case this.ENDPOINTS.DELETE_EQUITY_PROJECT_QUOTA:
				if(!project_id){
					throw 'Missing project_id param';
				}
				if(!quota_id){
					throw 'Missing quota_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + ENDPOINTS.EQUITY + "/" + project_id + "/" + ENDPOINTS.PRICES + "/" + quota_id;
			case this.ENDPOINTS.GET_PROJECT_DOCS:
			case this.ENDPOINTS.UPLOAD_PROJECT_DOC:
				if(!project_id){
					throw 'Missing project_id param';
				}
				if(!project_doc_type){
					throw 'Missing project_doc_type param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + project_id + "/" + ENDPOINTS.FILES + "/" + project_doc_type;
			case this.ENDPOINTS.DELETE_PROJECT_DOC:
				if(!project_id){
					throw 'Missing project_id param';
				}
				if(!project_doc_id){
					throw 'Missing project_doc_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + project_id + "/" + ENDPOINTS.FILES + "/" + project_doc_id;
			case this.ENDPOINTS.GET_PROJECT_UPDATES:
			case this.ENDPOINTS.CREATE_PROJECT_UPDATE:
				if(!project_id){
					throw 'Missing project_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + project_id + "/" + ENDPOINTS.UPDATES;
			case this.ENDPOINTS.DELETE_PROJECT_UPDATE:
			case this.ENDPOINTS.UPDATE_PROJECT_UPDATE:
				if(!project_id){
					throw 'Missing project_id param';
				}
				if(!project_update_id){
					throw 'Missing project_update_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + project_id + "/" + ENDPOINTS.UPDATES + "/" + project_update_id;
			case this.ENDPOINTS.GET_PROJECT_ORDERS:
				if(!project_id){
					throw 'Missing project_id param';
				}
				url = this.#API_endpoint + this.#offers_endpoint + "/" + project_id + "/" + ENDPOINTS.ORDERS;
				if(query_params){
					url += "?" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.EXPORT_PROJECT_ORDERS:
				if(!project_id){
					throw 'Missing project_id param';
				}
				url = this.#API_endpoint + this.#offers_endpoint + "/" + project_id + "/" + ENDPOINTS.ORDERS + "/" + ENDPOINTS.EXPORT;
				if(query_params){
					url += "?" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.PREPARE_ORDER:
				if(!project_id){
					throw 'Missing project_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + project_id + "/" + ENDPOINTS.ORDERS;
			case this.ENDPOINTS.CONFIRM_ORDER:
				if(!project_id){
					throw 'Missing project_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + project_id + "/" + ENDPOINTS.ORDERS + "/" + ENDPOINTS.CONFIRM;
			case this.ENDPOINTS.GET_ALL_ORDERS:
				url = this.#API_endpoint + this.#offers_endpoint + "/" + ENDPOINTS.ORDERS;
				if(query_params){
					url += "?" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.EXPORT_ALL_ORDERS:
				url = this.#API_endpoint + this.#offers_endpoint + "/" + ENDPOINTS.ORDERS + "/" + ENDPOINTS.EXPORT;
				if(query_params){
					url += "?" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.GET_USER_ORDERS:
				if(!user_id){
					throw 'Missing query_user_id param';
				}
				url = this.#API_endpoint + this.#offers_endpoint + "/" + ENDPOINTS.ORDERS;
				return url + "?" + new URLSearchParams({[ORDER_FIELDS.query_user_id]: user_id});
			case this.ENDPOINTS.GET_HOMEPAGE_PROJECTS:
				return this.#API_endpoint + this.#offers_endpoint + "/" + ENDPOINTS.HOME;
			case this.ENDPOINTS.SAVE_PROJECT_LIQUIDATION_DATA:
				if(!project_id){
					throw 'Missing project_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + project_id + "/" + ENDPOINTS.LIQUIDATE;
			case this.ENDPOINTS.GET_PROJECTS_WITH_STATUS:
				if(!status_id){
					throw 'Missing status_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + ENDPOINTS.STATUS + "/" + status_id;
			case this.ENDPOINTS.GET_PROJECT_REPORT:
				if(!project_id){
					throw 'Missing project_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + project_id + "/" + ENDPOINTS.REPORT;
			case this.ENDPOINTS.CHECK_USER_INVESTED_IN_PROJECT:
				if(!project_id){
					throw 'Missing project_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + project_id + "/" + ENDPOINTS.ORDERS + "/" + ENDPOINTS.CHECK;
			case this.ENDPOINTS.GET_PROJECT_REPORT_CSV:
				if(!project_id){
					throw 'Missing project_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + project_id + "/" + ENDPOINTS.CSV;
			case this.ENDPOINTS.CHANGE_ORDER_STATUS:
				if(!project_id){
					throw 'Missing order_id param';
				}
				if(!status_id){
					throw 'Missing status_id param';
				}
				if(!order_id){
					throw 'Missing order_id param';
				}
				return this.#API_endpoint + this.#offers_endpoint + "/" + project_id + "/" + ENDPOINTS.ORDERS + "/" + order_id + "/" + ENDPOINTS.STATUS + "/" + status_id;

			case this.ENDPOINTS.GET_CONTRACT_TEXT:
				if(!language_code){
					throw 'Missing language_code param';
				}
				return this.#API_endpoint + this.#texts_endpoint + "/" + ENDPOINTS.CONTRACT + "/" + language_code;
			case this.ENDPOINTS.GET_TOS:
				if(!language_code){
					throw 'Missing language_code param';
				}
				return this.#API_endpoint + this.#texts_endpoint + "/" + ENDPOINTS.TOS + "/" + language_code;

			case this.ENDPOINTS.GET_SURVEY:
				if(!language_code){
					throw 'Missing language_code param';
				}
				if(!survey_id){
					throw 'Missing survey_id param';
				}
				return this.#API_endpoint + this.#surveys_endpoint + "/" + language_code + "/" + survey_id;
			case this.ENDPOINTS.SEND_SURVEY_ANSWERS:
				if(!survey_id){
					throw 'Missing survey_id param';
				}
				return this.#API_endpoint + this.#surveys_endpoint + "/" + survey_id;
			case this.ENDPOINTS.GET_SURVEY_RESULTS:
				if(!survey_id){
					throw 'Missing survey_id param';
				}
				return this.#API_endpoint + this.#surveys_endpoint + "/" + ENDPOINTS.RESPONSES + "/" + survey_id;
			case this.ENDPOINTS.GET_USER_SURVEY_RESULTS:
				if(!survey_id){
					throw 'Missing survey_id param';
				}
				if(!user_id){
					throw 'Missing query_user_id param';
				}
				return this.#API_endpoint + this.#surveys_endpoint + "/" + ENDPOINTS.RESPONSES + "/" + survey_id + "/" + user_id;

			case this.ENDPOINTS.SEND_SIMULATION:
			case this.ENDPOINTS.GET_SIMULATIONS:
				return this.#API_endpoint + this.#simulations_endpoint;
			case this.ENDPOINTS.GET_USER_SIMULATIONS:
				if(!user_id){
					throw 'Missing query_user_id param';
				}
				return this.#API_endpoint + this.#simulations_endpoint + "/" + user_id;

			case this.ENDPOINTS.POST_PROPOSAL:
			case this.ENDPOINTS.GET_ALL_PROPOSALS:
				return this.#API_endpoint + this.#proposals_endpoint;
			case this.ENDPOINTS.UPDATE_PROPOSAL:
			case this.ENDPOINTS.GET_PROPOSAL:
				if(!proposal_id){
					throw 'Missing proposal_id param';
				}
				return this.#API_endpoint + this.#proposals_endpoint + "/" + proposal_id;

			case this.ENDPOINTS.GET_USER_ORDERS_DASHBOARD:
				return this.#API_endpoint + this.#dash_endpoint + "/" + ENDPOINTS.USER + "/" + ENDPOINTS.ORDERS;
			case this.ENDPOINTS.DASHBOARD_ADMIN:
				return this.#API_endpoint + this.#dash_endpoint + "/" + ENDPOINTS.ADMIN + "/" + ENDPOINTS.HOME;
			case this.ENDPOINTS.DASHBOARD_USER_UPDATES:
				return this.#API_endpoint + this.#dash_endpoint + "/" + ENDPOINTS.USER + "/" + ENDPOINTS.ORDERS + "/" + ENDPOINTS.UPDATES;
			case this.ENDPOINTS.DASHBOARD_USER_PERFORMANCES:
				return this.#API_endpoint + this.#dash_endpoint + "/" + ENDPOINTS.USER + "/" + ENDPOINTS.PERFORMANCES;
			case this.ENDPOINTS.DASHBOARD_USER_ASSETS:
				return this.#API_endpoint + this.#dash_endpoint + "/" + ENDPOINTS.USER + "/" + ENDPOINTS.ASSETS;
		}
	}
}

function handleWorkerMessage({data: msg}){
	const observers = WorkerMessageObserver[msg.code];

	const observerError = result => {
		const statusObj = result ? result.status ? result : result.result : result;
		if(statusObj){
			for(let i = observers.length - 1; i >= 0; --i){
				const o = observers[i];
				if(o.onFail){
					if(o.onFail(result) !== false){
						observers.splice(i, 1);
					}
				}
			}
		}
	};

	const observerSuccess = result => {
		for(let i = observers.length - 1; i >= 0; --i){
			const o = observers[i];
			if(o.onSuccess){
				if(o.onSuccess(result) !== false){
					observers.splice(i, 1);
				}
				continue;
			}
			observers.splice(i, 1);
		}
	};

	const result = msg.response;
	switch (msg.status){
		case WorkerResponse.ERROR:{
			console.error(msg);
			if(msg.http_code === 401 && msg.response === "Wrong token"){
				//const {t: $t} = useI18n(); //todo qui non gli piace
				const userStore = useUserStore();
				const appStore = useAppStore();
				const {expiredLogout} = storeToRefs(appStore);
				if(expiredLogout.value){ //errore già notificato; inutile ri-notificarlo
					return;
				}
				expiredLogout.value = true;
				appStore.showError({body: 'La sessione è scaduta. Fai nuovamente login per continuare ad operare.'}); //todo $t('global.session_expired')
				userStore.logout(true).then();
				return;
			}
			observerError(msg);
			break;
		}
		case WorkerResponse.SUCCESS:{
			observerSuccess({result: result, contentType: msg.response_type});
			break;
		}
	}
}