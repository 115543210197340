export const USER_TYPES = {
	PRIVATE: 1,
	BUSINESS: 2,
	ADMIN: 3
};

export const REQUESTS_STATUS = {
	PENDING: 0
}

export const PROJECT_CATEGORY = {
	EQUITY: Number(import.meta.env.VITE_PROJECT_CATEGORY_EQUITY_ID),
	DEBT: Number(import.meta.env.VITE_PROJECT_CATEGORY_DEBT_ID)
};

export const PROJECT_STATUS = {
	CREATED: 0,
	DRAFT: Number(import.meta.env.VITE_OFFER_STATUS_DRAFT_ID),
	READY: Number(import.meta.env.VITE_OFFER_STATUS_READY_ID),
	PUBLISHED: Number(import.meta.env.VITE_OFFER_STATUS_PUBLISHED_ID),
	COLLECTING: Number(import.meta.env.VITE_OFFER_STATUS_COLLECTING_ID),
	PERFECTING: Number(import.meta.env.VITE_OFFER_STATUS_PERFECTING_ID),
	CLOSED: Number(import.meta.env.VITE_OFFER_STATUS_CLOSED_ID),
	FUNDED: Number(import.meta.env.VITE_OFFER_STATUS_FUNDED_ID),
	DELETED: Number(import.meta.env.VITE_OFFER_STATUS_DELETED_ID),
	LIQUIDATED: Number(import.meta.env.VITE_OFFER_STATUS_LIQUIDATED_ID)
};

export const USER_STATUS = {
	UNVERIFIED: Number(import.meta.env.VITE_USER_STATUS_UNVERIFIED_ID),
	VERIFIED: Number(import.meta.env.VITE_USER_STATUS_VERIFIED_ID),
	DELETED: Number(import.meta.env.VITE_USER_STATUS_DELETED_ID),
	WATING: Number(import.meta.env.VITE_USER_STATUS_WAITING_ID),
	PENDING: Number(import.meta.env.VITE_USER_STATUS_PENDING_ID),
	ACTIVE: Number(import.meta.env.VITE_USER_STATUS_ACTIVE_ID)
};

export const COMPANY_STATUS = {
	DELETED: Number(import.meta.env.VITE_SPV_STATUS_DELETED_ID),
	DRAFT: Number(import.meta.env.VITE_SPV_STATUS_DRAFT_ID),
	PREVIEW: Number(import.meta.env.VITE_SPV_STATUS_PREVIEW_ID),
	COMPLETED: Number(import.meta.env.VITE_SPV_STATUS_COMPLETED_ID)
};

export const COMPANY_DOCUMENTS_TYPE_ID = {
	businessPlanTypeId: Number(import.meta.env.VITE_COMPANY_DOCS_BUSINESS_PLAN_ID),
	certificateTypeId: Number(import.meta.env.VITE_COMPANY_DOCS_CERTIFICATE_ID),
	othersTypeId: Number(import.meta.env.VITE_COMPANY_DOCS_OTHER_ID)
};

export const PROJECT_DOCUMENTS_TYPE_ID = {
	galleryTypeId: Number(import.meta.env.VITE_OFFER_FILE_TYPE_GALLERY_ID),
	videoTypeId: Number(import.meta.env.VITE_OFFER_FILE_TYPE_VIDEO_ID),
	projectTypeId: Number(import.meta.env.VITE_OFFER_FILE_TYPE_PROJECT_ID),
	financialTypeId: Number(import.meta.env.VITE_OFFER_FILE_TYPE_FINANCIAL_ID)
};

export const INVESTOR_PROFILES = {
	standard: Number(import.meta.env.VITE_INVESTOR_PROFILE_STANDARD_ID),
	pro: Number(import.meta.env.VITE_INVESTOR_PROFILE_PRO_ID)
};

export const TESTS_ID = {
	MIFID: Number(import.meta.env.VITE_MIFID_SURVEY_ID)
};

export const ORDER_STATUS = {
	pending: Number(import.meta.env.VITE_ORDER_STATUS_PENDING),
	perfected: Number(import.meta.env.VITE_ORDER_STATUS_PERFECTED),
	completed: Number(import.meta.env.VITE_ORDER_STATUS_COMPLETED),
	refunded: Number(import.meta.env.VITE_ORDER_STATUS_REFUNDED),
	cancelled: Number(import.meta.env.VITE_ORDER_STATUS_CANCELLED),
	expired: Number(import.meta.env.VITE_ORDER_STATUS_EXPIRED),
	to_be_refunded: Number(import.meta.env.VITE_ORDER_STATUS_TO_BE_REFUNDED)
};

export const PROPOSAL_STATUS = {
	accepted: Number(import.meta.env.VITE_PROPOSAL_STATUS_ACCEPTED),
	rejected: Number(import.meta.env.VITE_PROPOSAL_STATUS_REJECTED)
};

export const USER_FIELDS = {
	captcha: 'recaptcha_code',
	name: 'firstname',
	type: 'user_type_id',
	surname: 'lastname',
	email: 'email',
	email2: 'email2',
	password: 'password',
	password2: 'password2',
	id: 'id',
	business_name: 'business_name',
	phone: 'phone',
	country_birth: 'country_birth_id',
	birthday: 'birthdate',
	gender: 'gender_id',
	privacy: 'privacy',
	newsletter: 'inf_consent',
	token: 'token',
	last_update_by: 'last_update_by',
	last_update_date: 'last_update_date',
	create_by: 'create_by',
	creation_date: 'creation_date',
	vat: 'vat_code',
	city_birth: 'city_birth_id',
	nationality: 'citizenship_id',
	mfa: 'mfa',
	mfa_sid: 'mfa_sid',
	mfa_phone: 'mfa_phone',
	status: 'profile_status_id',
	residence_country: 'country_residence_id',
	residence_state: 'province_residence_code',
	residence_city: 'city_residence',
	residence_address: 'address',
	residence_street_number: 'street_number',
	residence_zip: 'postal_code',
	tax_id: 'tax_id',
	document_number: 'document_id_number',
	document_emission: 'document_emission_date',
	document_expire: 'document_expiry_date',
	document_type: 'document_type_id',
	document_front: 'document_pic_front',
	document_back: 'document_pic_back',
	document_front_id: 'document_pic_front_id',
	document_back_id: 'document_pic_back_id',
	investor_type: 'investor_type_id',
	requested_investor_type_change: 'investor_upgrade_request',
	investor_upgrade_data: 'investor_upgrade_type',
	profile_status: 'profile_status_id',
	directa_sim: 'directa_sim',
	fabrick: 'fabrick_id',
	contract: 'user_contract',
	contract_id: 'user_contract_id',
	mifid_valid_until: 'mifid_valid_until_date',
	mifid_locked_until: 'mifid_locked_until_date',
	mifid_incoherence: 'mifid_incoherence',
	losses_simulation_valid_until_date: 'losses_simulation_valid_until_date',
	rubrication: 'account_directasim',
	legal_name: 'legal_r_name',
	legal_surname: 'legal_r_surname',
	costitution_date: 'constitution_date',
	business_country: 'business_country_id',
	incorporation_certificate: 'incorporation_certificate',
	incorporation_certificate_id: 'incorporation_certificate_id',

	fullname: 'fullname' //solo per tabella utenti
};

export const CONTRACT_FIELDS = {
	blob_id: 'contract_blob_id',
	id: 'id',
	created_by: 'created_by',
	creation_date: 'creation_date',
	signature_date: 'date_signature',
	last_update_by: 'last_update_by',
	last_update_date: 'last_update_date'
};

export const INVESTOR_UPGRADE_FIELDS = {
	id: 'id',
	created_by: 'created_by',
	creation_date: 'creation_date',
	last_update_by: 'last_update_by',
	last_update_date: 'last_update_date',
	investor_type: 'investor_type',
	lbl_key: 'label_key_investor_type'
};

export const MEDIA_FIELDS = {
	extension: 'extension',
	filename: 'filename',
	id: 'id'
};

export const DOCUMENT_UPLOAD_FIELDS = {
	NUMBER: 'document_id_number',
	TYPE: 'document_type_id',
	EMISSION: 'emission_date',
	EXPIRY: 'expiry_date',
	FRONT: 'document_front',
	BACK: 'document_back'
};

export const PROJECTS_FIELDS = {
	id: 'id',
	category: 'offer_type_id',
	title: 'title',
	code: 'id',
	spv_id: 'spv_id',
	spv: 'spv',
	home: 'in_home',
	card_img: 'card_image',
	card_img_id: 'card_image_id',
	description: 'offer_description',
	rubrication: 'rubrication_allowed',
	publish_date: 'publish_date',
	funding_start_date: 'funding_start_date',
	funding_end_date: 'funding_end_date',
	real_funding_end_date: 'effective_funding_end_date',
	email_revoke: 'revoke_email',
	email_recess: 'recess_email',
	city: 'city_id',
	type: 'tipology',
	duration: 'duration',
	fabrick: 'fabrick_id',
	target_min: 't_min',
	target_max: 't_max',
	social_capital_min: 'ac_min',
	social_capital_max: 'ac_max',
	surcharge_min: 'min_overprice',
	surcharge_max: 'max_overprice',
	social_capital_pre: 'share_capital_before_offer',
	evaluation_pre: 'company_valuation_before_offer',
	fiscal_benefit_percentage: 'fiscal_benefit_percentage',
	post_offer_evaluation: 'post_offer_evaluation',
	platform_commissions_percentage: 'platform_commissions_percentage',
	time_schedule_id: 'time_schedule_doc_id',
	income_statement_id: 'income_statement_doc_id',
	financial_structure_id: 'financial_structure_doc_id',
	time_schedule: 'time_schedule_doc',
	income_statement: 'income_statement_doc',
	financial_structure: 'financial_structure_doc',
	latitude: 'latitude',
	longitude: 'longitude',
	product_type: 'product_type',
	warranty: 'warranty',
	emission_start: 'emission_start',
	emission_end: 'emission_end',
	emission_amount: 'emission_amount',
	bond_nominal_value: 'bond_nominal_value',
	min_investment: 'min_investment',
	isin: 'isin',
	rate_type: 'rate_type_id',
	refund_type: 'refund_type',
	quotation: 'quotation',
	rating: 'rating',
	coupon_months_cycle: 'coupon_months_cycle',
	refund_policy: 'refund_policy',
	annual_interest_rate: 'annual_interest_rate',
	amortization_schedule: 'amortization_schedule_doc',
	amortization_schedule_id: 'amortization_schedule_doc_id',
	balance_sheet: 'balance_sheet_doc',
	balance_sheet_id: 'balance_sheet_doc_id',
	quota_multiply: 'multiple_quota',
	status: 'status_id',
	depreciation: 'amortization_schedule',
	depreciation_id: 'amortization_schedule_doc_id',
	bank_accountholder: 'account_holder',
	bank_iban: 'iban',
	bank_bic: 'bic_swift',
	bank_name: 'bank_name',
	bank_address: 'address',
	consob: 'consob_requirement',
	safeguarded_money: 'safeguarded_money',
	independent_evaluation: 'independent_evaluation',
	liquidation_preference: 'liquidation_preference',
	new_corporate_transparency: 'new_corporate_transparency',
	pro_investor: 'pro_investor',
	sell_option: 'sell_option',
	quotas: 'prices',
	default_quota_id: 'default_price_id',
	default_product_id: 'default_product_id',
	products: 'products',
	equity_min: 'min_equity',
	equity_max: 'max_equity',
	raised: 'raised_funds',
	final_roi: 'final_roi',
	final_irr: 'final_irr',
	real_duration_start: 'real_duration_start',
	real_duration_end: 'real_duration_end',
	offer_calculated_values: 'offer_calculated_values',
	offer_equity_end_values: 'offer_equity_end_values',
	exit_date: 'exit_date',
	updates: 'updates',
	files: 'files',
	final_tai: 'tai',
	purchase_obligation: 'purchase_obligation',

	evaluation_post: 'company_valuation_after_offer',
	equity_final: 'equity_final'
};

export const PROJECT_CALCULATED_VALUES = {
	pro_users: 'pro_users_adhesions',
	raised_funds: 'raised_funds',
	total_users: 'total_adhesions',
	overfunding: 'overfunding'
};

export const PROJECT_EQUITY_END_VALUES = {
	ac_eff: 'ac_eff',
	final_equity_given: 'final_equity_given',
	post_offer_evaluation: 'post_offer_evaluation'
};

export const PROJECT_UPDATES_FIELDS = {
	title: 'title',
	subtitle: 'subtitle',
	description: 'description',
	upload_media: 'media_ids',
	upload_documents: 'document_ids',
	creation_date: 'creation_date',
	id: 'id',
	created_by: 'created_by',
	last_update_date: 'last_update_date',
	last_update_by: 'last_update_by',
	project: 'offer',
	project_id: 'offer_id',
	files: 'offer_update_files',

	status: 'status_id'
};

export const PROJECT_QUOTA_FIELDS = {
	id: 'id',
	default: 'default_price', //solo in upload
	name: 'name',
	min: 'min_investment',
	max: 'max_investment',
	value: 'nominal_value',
	additional_charge: 'unit_additional_charge',
	roi: 'expected_roi',
	irr: 'expected_irr',
	suitability: 'suitability_score_id',
	liquidation_preference: 'liquidation_preference',
	cosale: 'cosale_right',
	voting: 'voting_right',
	description: 'description'
};

export const PROJECT_PRODUCT_FIELDS = {
	id: 'id',
	default: 'default_product', //solo in upload
	type: 'product_type',
	rating: 'rating',
	rate_type: 'rate_type_id',
	coupon_months_cycle: 'coupon_months_cycle',
	suitability_score_id: 'suitability_score_id',
	tai: 'annual_interest_rate',
	refund: 'capital_refund',
	description: 'description'
};

export const COMPANY_SPVS_FIELDS = {
	id: 'id',
	business_name: 'business_name',
	vat_code: 'tax_id',
	fabrick: 'fabrick_id',
	bildap: 'bildap_id',
	status: 'spv_status_id',
	type: 'business_type',
	business_area: 'business_area_id',
	country: 'operative_country_id',
	birthday: 'establishment_date',
	employees: 'employees_number',
	logo: 'business_logo',
	cover: 'emotional_image',
	logo_id: 'business_logo_id',
	cover_id: 'emotional_image_id',
	share_capital: 'share_capital',
	equity_given: 'equity_given',
	latest_evaluation: 'latest_evaluation',
	description: 'company_description',
	website: 'website',
	facebook: 'facebook',
	instagram: 'instagram',
	linkedin: 'linkedin',
	x: 'twitter',
	youtube: 'youtube',
	attorney_name: 'legal_r_name',
	attorney_surname: 'legal_r_surname',
	attorney_tax_id: 'legal_r_tax_id',
	attorney_email: 'legal_r_email',
	attorney_phone: 'legal_r_phone',
	attorney_gender_id: 'legal_r_gender_id',
	attorney_birthdate: 'legal_r_birthdate',
	attorney_country_birth: 'legal_r_country_birth_id',
	attorney_state_birth: 'legal_r_province_birth_code',
	attorney_city_birth: 'legal_r_city_birth_id',
	bank_accountholder: 'account_holder_name',
	iban: 'iban',
	bic: 'bic_swift',
	bank_document: 'bank_document',
	bank_document_id: 'bank_document_id'
};

export const SPV_DOCUMENT_FIELDS = {
	media: 'media_id',
	description: 'description'
};

export const PROJECT_DOCUMENT_FIELDS = {
	media: 'media_id',
	description: 'description'
};

export const SPV_ECONIMIC_DATA_FIELDS = {
	spv: 'spv_id',
	date: 'date',
	amount: 'amount',
	lead_investor: 'lead_investor',
	fund_channel_id: 'fund_channel_id'
};

export const TEAM_SPV_FIELDS = {
	id: 'member_id',
	spv_id: 'spv_id',
	name: 'name',
	surname: 'surname',
	role: 'member_role',
	description: 'description',
	avatar_id: 'avatar_blob_id',
	avatar: 'avatar_blob'
};

export const DOCUMENT_SPV_FIELDS = {
	created_by: 'created_by',
	creation_date: 'creation_date',
	id: 'document_id',
	last_update_date: 'last_update_date',
	last_update_by: 'last_update_by',
	media: 'media',
	media_id: 'media_id',
	spv: 'spv',
	document_type: 'spv_document_type',
	document_type_id: 'spv_document_type_id',
	spv_id: 'spv_id'
};

export const DOCUMENT_PROJECT_FIELDS = {
	created_by: 'created_by',
	creation_date: 'creation_date',
	id: 'id',
	document_id: 'document_id',
	last_update_date: 'last_update_date',
	last_update_by: 'last_update_by',
	media: 'media',
	media_id: 'media_id',
	project: 'offer',
	document_type: 'offer_document_type',
	document_type_id: 'offer_document_type_id',
	file_type: 'file_type',
	file_type_id: 'file_type_id',
	project_id: 'offer_id'
};

export const DOCUMENT_UPDATE_FIELDS = {
	created_by: 'created_by',
	creation_date: 'creation_date',
	id: 'document_id',
	last_update_date: 'last_update_date',
	last_update_by: 'last_update_by',
	media: 'media',
	media_id: 'media_id',
	project: 'offer',
	file_type: 'file_type',
	file_type_id: 'file_type_id',
	update_id: 'offer_update_id'
};

export const QUERY_FIELDS = {
	order_by: 'orderBy',
	asc: 'asc',
	page: 'page',
	page_size: 'page_size'
};

export const TEXT_FIELDS = {
	KEY: 'text_key',
	LANGUAGE: 'language_code',
	VALUE: 'text_value'
};

export const MIFID_TEST_FIELDS = {
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date',
	ID: 'id',
	LBL: 'label',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	QUESTIONS: 'questions',
	TIME: 'duration'
};

export const MIFID_TEST_QUESTION_FIELDS = {
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date',
	ID: 'id',
	TXT: 'text',
	TXT_KEY: 'text_key',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	SURVEY: 'survey',
	SURVEY_ID: 'survey_id',
	ANSWERS: 'answers'
};

export const MIFID_TEST_ANSWER_FIELDS = {
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date',
	ID: 'id',
	VAL: 'answer_value',
	REQUIRED: 'coercion',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	INCOHERENCE: 'incoherence',
	QUESTION: 'question',
	QUESTION_ID: 'question_id',
	TXT: 'text',
	TXT_KEY: 'text_key'
};

export const MIFID_TEST_ANSWER_UPLOAD_FIELDS = {
	answer_ids: 'answer_ids',
	start_date: 'start_date',
	end_date: 'end_date',
	accepted: 'accepted',
	privacy_consent: 'privacy_consent'
};

export const MIFID_RESULT_FIELDS = {
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date',
	ID: 'id',
	COERCION: 'coercion',
	INCOHERENCE: 'incoherence',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	SURVEY: 'survey',
	SURVEY_ID: 'survey_id',
	SCORE: 'total_score',
	MEDIA_ID: 'document_id',
	MEDIA: 'document'
};

export const LOSS_SIMULATION_FIELDS = {
	ANNUAL_INCOME: 'net_annual_income',
	LIQUID_ASSETS: 'total_liquid_assets',
	ANNUAL_FINANCIAL_COMMITMENTS: 'annual_financial_commitments',
	INFORMATIONS_DATE: 'informations_date',
	CREATED_BY: 'created_by',
	CREATION_DATE: 'creation_date',
	LAST_UPDATE_BY: 'last_update_by',
	LAST_UPDATE_DATE: 'last_update_date',
	USER: 'user',
	ID: 'id',
	MAX_LOSS: 'max_losses_capacity',
	MAX_SINGLE_OPERATION: 'max_losses_single_operation',
	NET_WORTH: 'net_worth',
	MEDIA: 'document',
	MEDIA_ID: 'document_id',

	ACCEPTED: 'accepted',
	PRIVACY: 'privacy_consent',
	SAVING: 'saving_accepted'
};

export const ORDER_FIELDS = {
	id: 'id',
	creation_date: 'creation_date',
	created_by: 'created_by',
	last_update_date: 'last_update_date',
	last_update_by: 'last_update_by',
	iban: 'iban',
	user: 'owner',
	status: 'order_status_id',
	investor_id: 'investor_id',
	investor: 'investor',
	quota_id: 'price_id',
	product_id: 'product_id',
	offer_id: 'offer_id',
	quota: 'price',
	product: 'product',
	offer: 'offer',
	amount: 'amount',
	fabrick: 'fabrick_id',
	rubrication: 'rubrication',
	directa: 'account_directasim',
	personal_data_consent: 'personal_data_consent',
	risk_awareness_consent: 'risk_awareness_consent',
	payment_reference: 'payment_reference',
	mifid: 'survey_response',
	loss_simulation: 'losses_simulation',
	partial_equity_given: 'partial_equity_given',
	kiis: 'kiis',
	investment_return: 'investment_return',
	shareholder_agreement: 'shareholder_agreement',
	//solo per filtri
	query_category_id: 'offer_type_id',
	query_user_id: 'user_id',
	query_date: 'date',
	query_label: 'label',
	query_quota_product_name: 'name'
};

export const ORDER_PREPARE_FIELDS = {
	//upload
	quota_id: 'price_id',
	product_id: 'product_id',
	amount: 'amount',
	//risposta
	error: 'error_id',
	financial_knowledge_fail: 'financial_knowledge_fail',
	losses_simulation_fail: 'losses_simulation_fail',
	max_loss_single: 'max_losses_single_operation',
	mifid_survey_fail: 'mifid_survey_fail',
	mifid: 'mifid_survey',
	loss_simulation: 'losses_simulation',
	warranty: 'debt_warranty',
//miei
	project: 'project',
	quota: 'price',
	product: 'product',
};

export const PROPOSALS_FIELDS = {
	id: 'id',
	business_name: 'business_name',
	vat_code: 'vat_code',
	website: 'website',
	referral_name: 'referral_name',
	referral_surname: 'referral_surname',
	referral_mail: 'referral_mail',
	referral_phone: 'referral_phone',
	offer_type_id: 'offer_type_id',
	location: 'location',
	scope: 'scope_id',
	duration: 'duration_id',
	privacy: 'privacy',
	inf_consent: 'inf_consent',
	recaptcha_code: 'recaptcha_code',
	status: 'status_id',
	creation_date: 'creation_date'
};

export const DASHBOARD_ADMIN_FIELDS = {
	ACTIVE_PROJECTS: 'active_offers_count',
	ORDERS: 'orders_count',
	USERS_REGISTERED: 'users_registered_count',
	USERS_UNVERIFIED: 'users_to_be_verified_count'
};

export const DASHBOARD_USER_PERFORMANCES_FIELDS = {
	DEBT_CURR_AVG: 'debt_curr_perf_weighted_avg',
	DEBT_CURR_TOTAL: 'debt_current_invested_amount',
	DEBT_LIQ_AVG: 'debt_liq_perf_weighted_avg',
	DEBT_LIQ_TOTAL: 'debt_liquidated_amount',
	DEBT_TOTAL_INVESTED: 'debt_total_invested_amount',
	EQUITY_CURR_AVG: 'equity_curr_perf_weighted_avg',
	EQUITY_CURR_TOTAL: 'equity_current_invested_amount',
	EQUITY_LIQ_AVG: 'equity_liq_perf_weighted_avg',
	EQUITY_LIQ_TOTAL: 'equity_liquidated_amount',
	EQUITY_TOTAL_INVESTED: 'equity_total_invested_amount'
};

export const PROJECT_REPORT_FIELDS = {
	amount_avg: 'amount_avg',
	amount_max: 'amount_max',
	amount_min: 'amount_min',
	business_investors: 'business_investors',
	business_investors_percentage: 'business_investors_percentage',
	business_pro_investors: 'business_pro_investors',
	cluster_1: 'cluster_1',
	cluster_2: 'cluster_2',
	cluster_3: 'cluster_3',
	cluster_4: 'cluster_4',
	cluster_5: 'cluster_5',
	cluster_6: 'cluster_6',
	cluster_7: 'cluster_7',
	orders: 'orders',
	over_value_business_investors_amount: 'over_value_business_investors_amount',
	over_value_business_investors_subs: 'over_value_business_investors_subs',
	over_value_private_investors_amount: 'over_value_private_investors_amount',
	over_value_private_investors_subs: 'over_value_private_investors_subs',
	post_offer_share_capital: 'post_offer_share_capital',
	prices: 'prices',
	private_investors: 'private_investors',
	private_pro_investors: 'private_pro_investors',
	products: 'products',
	revoked_orders: 'revoked_orders',
	success: 'success',
	total_amount: 'total_amount',
	under_value_business_investors_amount: 'under_value_business_investors_amount',
	under_value_business_investors_subs: 'under_value_business_investors_subs',
	under_value_private_investors_amount: 'under_value_private_investors_amount',
	under_value_private_investors_subs: 'under_value_private_investors_subs',
	equity_end_values: 'equity_end_values'
};