import "./assets/reset.css";
import './assets/main-static.less';

import {createApp, markRaw} from 'vue';
import {createPinia} from 'pinia';

import App from './App.vue';
import router from './router';
import {i18n} from './i18n';

import resetStore from "@/stores/reset-store";
import '@mdi/font/css/materialdesignicons.css';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import VueUploadComponent from 'vue-upload-component';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import VueGoogleMaps from 'vue-google-maps-community-fork';
import {cookieBot} from '@ambitiondev/vue-cookiebot';

import {InferSeoMetaPlugin} from '@unhead/addons';

// Vuetify
import 'vuetify/styles';
import {createVuetify} from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import {createVueI18nAdapter} from "vuetify/locale/adapters/vue-i18n";
import {useI18n} from "vue-i18n";

import * as Sentry from "@sentry/vue";
import {VueRecaptchaPlugin} from "vue-recaptcha/head";
import {createHead} from "@unhead/vue";

const vuetify = createVuetify({
	components: {...components},
	directives,
	icons: {
		defaultSet: 'mdi' // This is already the default value - only for display purposes
	},
	locale: {
		adapter: createVueI18nAdapter({i18n, useI18n}),
	}
});

const app = createApp(App);
app.use(VueRecaptchaPlugin, {
	v2SiteKey: import.meta.env.VITE_CAPTCHA_V2_SITE,
	v3SiteKey: import.meta.env.VITE_CAPTCHA_V3_SITE
});

app.use(VueGoogleMaps, {
	load: {
		key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY
	}
});

const pinia = createPinia();
pinia.use(resetStore);
pinia.use(piniaPluginPersistedstate);

pinia.use(({store}) => {
	store.router = markRaw(router)
});

app.use(pinia);
if(!import.meta.env.DEV){
	const isProd = import.meta.env.MODE === 'prod';
	const sentryIntegrations = [Sentry.browserTracingIntegration({router}),
		Sentry.replayIntegration(),
		// The following is all you need to enable canvas recording with Replay
		//per ora commento visto non funziona bene - riprovare in futuro
		//	Sentry.replayCanvasIntegration()
	];
	if(!isProd){
		sentryIntegrations.push(Sentry.feedbackIntegration({
			// Additional SDK configuration goes in here, https://docs.sentry.io/platforms/javascript/user-feedback/configuration/
			showBranding: false,
			isEmailRequired: true
		}));
	}
	Sentry.init({
		app,
		environment: import.meta.env.MODE,
		release: import.meta.env.VITE_SENTRY_PROJECT_NAME + "@" + __APP_VERSION__,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: sentryIntegrations,
		// Performance Monitoring
		tracesSampleRate: .01, // Capture 1% of the transactions
		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost", /^https:\/\/bildap\.it\/api/, /^https:\/\/bildap-api-prod\.azurewebsites\.net\/api/, /^https:\/\/bildap-api-stag\.azurewebsites\.net\/api/, /^https:\/\/bildap-api-dev\.azurewebsites\.net\/api/],
		// Session Replay
		replaysSessionSampleRate: .0005, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 0.91, // If you're not already sampling the entire session, change the sample rate to 91% when sampling sessions where errors occur.
		beforeSend(event, hint){ //https://docs.sentry.io/platforms/javascript/guides/vue/user-feedback/#integration
			// Check if it is an exception, and if so, show the report dialog
			if(!isProd && event.exception){
				Sentry.showReportDialog({eventId: event.event_id});
			}
			return event;
		}
	});
}

app.config.globalProperties.$filters = {
	formatCurrency(number){
		return Intl.NumberFormat("it-IT", {style: "currency", currency: "EUR", useGrouping: "always"}).format(number);
	},
	formatPercentage(number){
		return Intl.NumberFormat("it-IT", {
			style: "percent",
			useGrouping: "always",
			minimumFractionDigits: 2
		}).format(number);
	},
	formatPercentageSign(number){
		return Intl.NumberFormat("it-IT", {
			style: "percent",
			useGrouping: "always",
			signDisplay: "exceptZero"
		}).format(number);
	},
	formatDate(date){
		return Intl.DateTimeFormat("it-IT").format(new Date(date));
	}
}

const head = createHead({
	plugins: [
		InferSeoMetaPlugin()
	]
});
app.use(head);

app.use(cookieBot, {cookieBotId: import.meta.env.VITE_COOKIE_BOT_ID, blockingMode: 'auto'});
app.use(i18n);
app.use(router);
app.use(vuetify);
app.component('file-upload', VueUploadComponent);
app.component('VueDatePicker', VueDatePicker);

app.mount('#bildap');
